import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useFeedbackMutation } from 'csam/api/api';
import FeedbackModelTranslatiions from 'csam/translations/FeedbackModelTranslatiions';
import { fixed } from 'csam/utils/Constants';
import * as React from 'react';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface FeedbackModalProps {
  open: boolean;
  onClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, onClose }) => {
  const [clickedValue, setClickedValue] = React.useState<number | null>(null);
  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const [feedback, setFeedback] = React.useState({ rating_number: 0, comment: '' });

  const handleRatingClick = (value: number) => {
    setClickedValue(value === clickedValue ? null : value);
  };

  const handleRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback((prevState) => ({ ...prevState, rating_number: Number(event.target.value) }));
  };

  const getTranslation = (key: keyof (typeof FeedbackModelTranslatiions)['en']) =>
    FeedbackModelTranslatiions[localeFromStorage as keyof typeof FeedbackModelTranslatiions]?.[key] ||
    FeedbackModelTranslatiions.en[key];

  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback((prevState) => ({ ...prevState, comment: event.target.value }));
  };

  const feedBackMutaion = useFeedbackMutation(['sendFeedback'], (data) => ({
    url: `${fixed}feedback/add`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data,
  }));

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await feedBackMutaion.mutateAsync(feedback, {
        onSuccess: () => {
          setFeedback({ rating_number: 0, comment: '' });
          setClickedValue(0);
          onClose();
          toast.success('Feedback submitted successfully');
        },

        onError: () => {
          console.log('An error occurred while sending feedback.');
        },
      });
    } catch (error) {
      console.error('An error occurred while sending feedback.', error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="feedbacModal"
      disableScrollLock
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          {getTranslation('title')}
        </Typography>
        <Button aria-label="close" className="closeModal" onClick={onClose}>
          <CloseIcon />
        </Button>
        <Typography variant="body1" id="modal-modal-description" sx={{ mt: 2 }}>
          {getTranslation('subTitle')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box>
            <Box className="ratingWrapper" sx={{ display: 'flex' }}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                <input
                  key={value}
                  className={`ratingInput ${value === clickedValue ? 'clicked' : ''}`}
                  type="button"
                  value={value}
                  onChange={handleRatingChange}
                  onClick={() => {
                    handleRatingClick(value);
                    setFeedback((prevState) => ({ ...prevState, rating_number: value }));
                  }}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <small>{getTranslation('leastSatisfied')}</small>
              <small>{getTranslation('mostSatisfied')}</small>
            </Box>
          </Box>
          <textarea
            onChange={handleCommentChange}
            className="feedBackMessage"
            value={feedback.comment}
            placeholder={getTranslation('addComments')}
          />
          <Button type="submit" className="submitFeedback" data-testid="button" name="submit">
            {getTranslation('submit')}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
export default FeedbackModal;
