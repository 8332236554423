import adminRoutes from 'csam/adminRoutes';
import Layout from 'csam/components/Layout';
import DataProtection from 'csam/pages/DataProtection';
import { lazy } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import TrainingPlatform from 'csam/pages/TrainingPlatform';
import TrainingPlatformAs from './pages/TrainingPlatformAS';
import OctPishingContest from './pages/OctPishingContest';



const Archives = lazy(() => import('csam/pages/Archives'));
const Gamecontests = lazy(() => import('csam/pages/GameContest'));
const Goodreads = lazy(() => import('csam/pages/GoodReads'));
const Homepage = lazy(() => import('csam/pages/Home'));
const Monthbanner = lazy(() => import('csam/pages/MonthBanner'));
const Toolsresources = lazy(() => import('csam/pages/ToolsResources'));
const ComingSoon = lazy(() => import('csam/pages/ComingSoon'));
const Leaderboard = lazy(() => import('csam/pages/Leaderboard'));
const Maintenance = lazy(() => import('csam/pages/Maintenance'));
const GameSingle = lazy(() => import('csam/pages/GameSingle'));
const PageNotFound = lazy(() => import('csam/pages/PageNotFound'));
const InternalServerError = lazy(() => import('csam/pages/InternalServerError'));
const QuizSingle = lazy(() => import('csam/pages/QuizSingle'));
const Opps = lazy(() => import('csam/pages/Opps'));
const PrivacyPolicy = lazy(() => import('csam/pages/PrivacyPolicy'));
const OctMonth = lazy(() => import('csam/pages/OctMonth'));
const OctActivities = lazy(() => import('csam/pages/OctActivities'));
const OctPledge = lazy(() => import('csam/pages/OctPledge'));


const routes = [
  {
    path: '/',
    element: <Navigate to="/en" />,
  },
  {
    path: '/:locale',
    handle: {
      label: <Layout />,
    },
    children: [
      {
        index: true,
        element: <Homepage />,
      },
      {
        path: 'Monthbanner',
        element: <Monthbanner />,
      },
      {
        path: 'Goodreads',
        element: <Goodreads />,
      },
      {
        path: 'Gamecontests',
        element: <Gamecontests />,
      },
      {
        path: 'training-platform',
        element: <TrainingPlatform />,
      },
      {
        path: 'application-security',
        element: <TrainingPlatformAs />,
      },
      {
        path: 'training-thanks',
        element: <TrainingPlatform />,
      },
      {
        path: 'contest',
        element: <Gamecontests />,
      },
      {
        path: 'Toolsresources',
        element: <Toolsresources />,
      },
      {
        path: 'Archives',
        element: <Archives />,
      },
      {
        path: 'Leaderboard',
        element: <Leaderboard />,
      },
      {
        path: 'internal-server-error',
        element: <InternalServerError />,
      },
      {
        path: '/:locale/*',
        element: <PageNotFound />,
      },
      {
        path: '*',
        element: <Navigate to="/en/404" replace />,
      },
      {
        path: 'maintenance',
        element: <Maintenance />,
      },
      {
        path: 'comming-soon',
        element: <ComingSoon />,
      },
      {
        path: 'comming-soon-data-protection',
        element: <ComingSoon />,
      },
      {
        path: 'data-protection',
        element: <DataProtection />,
      },
      {
        path: 'game-page',
        element: <GameSingle />,
      },
      {
        path: 'play-quiz',
        element: <QuizSingle />,
      },
      {
        path: 'oops',
        element: <Opps />,
      },
      {
        path: '2024',
        element: <OctMonth />,
      },
      {
        path: '2024/activity',
        element: <OctActivities />,
      },
      {
        path: '2024/oct-pledge',
        element: <OctPledge />,
      },
      {
        path: '2024/oct-phisihing-contest',
        element: <OctPishingContest />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
    ],
  },
  ...adminRoutes,
];

const router = createBrowserRouter(routes);

export default router;
