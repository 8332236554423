import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import 'csam/App.css';
import 'csam/Custom.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FeedbackModal from 'csam/components/FeedbackModal';
import LocaleContext from 'csam/components/LocaleContext';
import router from 'csam/router';
import theme from 'csam/theme/theme';
import { Suspense, useMemo, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [locale, setLocale] = useState('en');
  const contextValue = useMemo(() => ({ locale, setLocale }), [locale, setLocale]);
  const localeFromStorage = localStorage.getItem('locale') || locale;

  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const queryClient = new QueryClient();

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const buttonText = {
    en: 'Feedback',
    es: 'Realimentación',
    pt: 'Feedback',
    zh: '反馈',
  };

  const buttonClass = {
    en: 'btn_en',
    es: 'btn_es',
    pt: 'btn_pt',
    zh: 'btn_zh',
  };

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <LocaleContext.Provider value={contextValue}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={{}}>
          <QueryClientProvider client={queryClient}>
            <Suspense>
              <RouterProvider router={router} />
            </Suspense>
            <Button
              onClick={handleOpen}
              className={`doFeedBack ${buttonClass[localeFromStorage as keyof typeof buttonClass]}`}
            >
              {buttonText[localeFromStorage as keyof typeof buttonText] || buttonText.en}
            </Button>
            <FeedbackModal open={modalOpen} onClose={handleClose} />
          </QueryClientProvider>
        </MsalAuthenticationTemplate>

        {inProgress === InteractionStatus.None && !isAuthenticated && (
          <UnauthenticatedTemplate>
            <Typography>Your are not Authorized</Typography>
          </UnauthenticatedTemplate>
        )}
      </LocaleContext.Provider>
    </ThemeProvider>
  );
};

export default App;