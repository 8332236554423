import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface QuestionData {
  status: number;
  quiz_id: number;
  question_text: string;
  [key: string]: string | number;
}

interface TranslationData {
  question_text: string;
  [key: string]: unknown;
}

interface State {
  data: QuestionData;
  translations: {
    es: TranslationData;
    pt: TranslationData;
    zh: TranslationData;
  };
}

const AddQuizQuestions: React.FC = () => {
  const initialState = {
    data: { quiz_id: 0, status: 0, question_text: '' },
    translations: {
      es: { question_text: '' },
      pt: { question_text: '' },
      zh: { question_text: '' },
    },
  };

  const { locale } = useContext(LocaleContext);
  const [questionData, setQuestionData] = useState<State>(initialState);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const slug = `quiz_questions`;

  const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
    slug,
    initialState,
  );

  useEffect(() => {
    if (!id) {
      setLocale('en');
    }
  }, [id, setLocale]);

  const query = useAuthenticatedQuery(
    ['question', id],
    {
      url: `${fixed}admin/en/quiz_questions/${id}`,
    },
    {
      enabled: !!id,
    },
  );
  useEffect(() => {
    if (query.data && query.data.success) {
      setQuestionData(query.data.data as State);
    }
  }, [query.data]);

  const isUpdateForm = Boolean(id);

  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['uupdate-question', 'PUT'] : ['add-question', 'POST'],
    (data) => ({
      url: `${fixed}admin/${locale}/quiz_questions${isUpdateForm ? `/${id}` : ''}`,
      method: isUpdateForm ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      data,
    }),
  );

  const {
    data: quizData,
    error: quizError,
    isPending: quizIsPending,
  } = useAuthenticatedQuery(['fetch-quiz', 'GETQUIZ'], {
    url: `${fixed}admin/en/quiz_contests`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  interface QuizData {
    id: number;
    name: string;
  }

  if (quizIsPending) return <AdminPageLoader />;
  if (quizError) return <div>Error: {quizError.message}</div>;
  if (quizData && !quizData.success) return <AdminInternalError />;

  if (translatePending) return <AdminPageLoader />;
  if (errorTranslate) return <div>Error: {errorTranslate.message}</div>;
  if (translateData && !translateData.success) return <AdminInternalError />;

  const handleChange = (event: { target: { name: string; value: string | number; checked?: boolean } }) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? 1 : 0;
    }

    if (translateData) {
      const translatableFields = translateData.data;

      setQuestionData((prevState) => {
        let newData = prevState.data;
        let newTranslations = prevState.translations;

        if (locale !== 'en' && translatableFields.includes(name)) {
          newTranslations = {
            ...prevState.translations,
            [locale as keyof typeof prevState.translations]: {
              ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
              [name]: value,
            },
          };
        } else {
          newData = {
            ...prevState.data,
            [name]: value,
          };
        }

        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const data = { ...questionData };

    if (data) {
      delete data.translations;
    }

    appendToFormData(formData, data, 'data');
    if (questionData?.translations) {
      Object.entries(questionData.translations).forEach(([localeKey, translation]) => {
        appendToFormData(formData, translation, `translations[${localeKey}]`);
      });
    }

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/questions');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetLocale = (newLocale: string) => {
    if (locale === 'en' && newLocale !== 'en') {
      for (const key in questionData.data) {
        if (
          questionData.data[key] === undefined ||
          questionData.data[key] === '' ||
          (questionData.data[key] === 0 && key !== 'status')
        ) {
          toast.error(`Please fill in the ${key} field first`);
          return;
        }
      }
    }

    setLocale(newLocale);
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit Question' : 'Add Question'} </Typography>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <InputLabel id="year-label">Select Quiz </InputLabel>
            <Select
              fullWidth
              labelId="year-label"
              name="quiz_id"
              size="small"
              value={
                questionData?.data?.quiz_id && questionData?.data?.quiz_id !== 0
                  ? questionData?.data?.quiz_id?.toString()
                  : ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('quiz_id')}
              required={locale === 'en'}
            >
              {(quizData.data as QuizData[]).map((quiz: QuizData) => (
                <MenuItem key={quiz.id} value={quiz.id.toString()}>
                  {quiz.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item sm={6}>
            <div className="form-group">
              <InputLabel id="monthTitle-label">
                Question <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Question"
                size="small"
                name="question_text"
                value={
                  locale === 'en'
                    ? questionData.data.question_text
                    : questionData.translations[locale as keyof State['translations']]?.question_text || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('question_text')}
                fullWidth
              />
            </div>
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="status-label">Status</InputLabel>
            <Grid container alignItems="center">
              <Typography>Inactive</Typography>
              <Switch
                id="status-label"
                name="status"
                onChange={handleChange}
                checked={Boolean(questionData.data.status)}
                disabled={locale !== 'en'}
              />
              <Typography>Active</Typography>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AdminWrapper>
  );
};

export default AddQuizQuestions;
