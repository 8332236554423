import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Questions: React.FC = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-quiz'], (ids) => ({
    url: `${fixed}admin/${locale}/quiz_questions`,
    method: 'DELETE',
    data: { ids },
  }));

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleDelete = (ids?: string[]) => {
    const idsToDelete = ids || selectedIds;
    deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
      onSuccess: () => {
        toast.success(`Record Deleted Successfully`);
        queryClient.invalidateQueries({
          queryKey: ['QuestionsPage', 'QuestionsPageData', locale],
        });
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 40,
      renderCell: (params) => params.value,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      renderCell: (params) => (Number(params.value) === 1 ? 'Active' : 'Inactive'),
    },

    {
      field: 'question_text',
      headerName: 'Question',
      width: 300,
      renderCell: (params) => (params.row.question_text ? params.row.question_text : 'N/A'),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 150,
      renderCell: (params) => {
        const date = new Date(params.value);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            sx={{ py: 1, px: 2, mr: 1, minWidth: 'fit-content' }}
            color="primary"
            title="Add Option"
            onClick={() => {
              navigate(`/admin/options/create?id=${params.row.id}`);
            }}
          >
            <AddIcon sx={{ fontSize: '18px' }} />
          </Button>

          <Button
            variant="contained"
            sx={{ py: 1, px: 2, mr: 1, minWidth: 'fit-content' }}
            color="secondary"
            onClick={() => {
              navigate(`edit/${params.row.id}`);
            }}
          >
            <EditIcon sx={{ fontSize: '18px' }} />
          </Button>
          <Button
            variant="contained"
            sx={{ py: 1, px: 2, mr: 1, minWidth: 'fit-content' }}
            onClick={() => handleDelete([params.row.id])}
            color="error"
          >
            <DeleteOutlineIcon sx={{ fontSize: '18px' }} />
          </Button>
        </>
      ),
    },
  ];

  interface QuestionsData {
    id: number;
    question_text: string;
    quiz_id: number;
    created_at: string;
    status: string;
  }

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<QuestionsData>(
    ['QuestionsPage', 'QuestionsPageData', locale],
    {
      url: `${fixed}admin/${locale || localeFromStorage}/quiz_questions`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6">Questions</Typography>
          <Button
            component={Link}
            to="create"
            sx={{ color: '#fff', lineHeight: '26px' }}
            variant="contained"
            size="small"
            color="success"
          >
            <AddIcon sx={{ fontSize: '18px' }} /> Add New
          </Button>
          <Button sx={{ lineHeight: '26px' }} variant="contained" size="small" color="error">
            <DeleteOutlineOutlinedIcon sx={{ fontSize: '18px' }} /> Bulk Delete
          </Button>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={setLocale} />
      </Box>

      <Box>
        {data && data.data ? (
          <DataGrid
            rows={data.data as unknown as readonly QuestionsData[]}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              console.log(newSelection);
              setSelectedIds(newSelection.map((id) => id.toString()) as string[]);
            }}
          />
        ) : (
          <AdminPageLoader />
        )}
      </Box>
    </AdminWrapper>
  );
};

export default Questions;
