import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getCommonColumns } from 'csam/admin/components/CommonColumns';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

const OctWeeks = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-Option'], (ids) => ({
    url: `${fixed}admin/${locale}/october_weeks`,
    method: 'DELETE',
    data: { ids },
  }));

  const queryClient = useQueryClient();

  const handleDelete = (ids?: string[]) => {
    const idsToDelete = ids || selectedIds;
    deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
      onSuccess: () => {
        toast.success(`Record Deleted Successfully`);
        queryClient.invalidateQueries({
          queryKey: ['OctWeeksPage', 'OctWeeksPageData', locale],
        });
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 40,
      renderCell: (params) => params.value,
    },
    {
      field: 'week',
      headerName: 'Week',
      width: 100,
      renderCell: (params) => params.value,
    },
    {
      field: 'week_title',
      headerName: 'Week Title',
      width: 200,
      renderCell: (params) => params.value,
    },
    {
      field: 'image',
      headerName: 'Image',
      width: 200,
      renderCell: (params) => params.value,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      renderCell: (params) => (params.value ? 'Active' : 'Inactive'),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 150,
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    ...getCommonColumns({ handleDelete }),
  ];

  interface WeeksData {
    id: number;
    week: string;
    week_title: string;
    image: string | null;
    status: boolean;
    created_at: string;
    updated_at: string;
    year_id: number;
  }

  interface WeeksResponse {
    success: boolean;
    data: WeeksData[];
    translatableFields: string[];
  }

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<WeeksResponse[]>(['OctWeeksPage', 'OctWeeksPageData', locale], {
    url: `${fixed}admin/${locale || localeFromStorage}/october_weeks`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  return (
    <AdminWrapper>
      <AdminPageHeader
        title="October Weeks"
        locale={locale}
        setLocale={setLocale}
        createLink="create"
        enableBulkDelete
      />
      <AdminDataGrid<WeeksResponse>
        data={data}
        columns={columns}
        setSelectedIds={setSelectedIds}
      />
    </AdminWrapper>
  );
};

export default OctWeeks;