const menuNames: { [key: string]: { name: string; route?: string; submenu?: { name: string; route: string }[] }[] } = {
  en: [
    { name: 'Home', route: '/' },
    {
      name: 'Activities',
      submenu: [
        { name: 'Good Reads', route: '/Goodreads' },
        { name: 'Games & Contest', route: '/Gamecontests' },
      ],
    },
    {
      name: 'GISP & Resources',
      submenu: [
        { name: 'GISP', route: '/Toolsresources' },
        { name: 'Data Protection', route: '/data-protection' },
        { name: 'Digital Ethics', route: '/Toolsresources' },
        { name: 'Training', route: '/Toolsresources' },
      ],
    },
    { name: 'Archives', route: '/Archives' },
  ],
  es: [
    { name: 'Inicio', route: '/' },
    {
      name: 'Actividades',
      submenu: [
        { name: 'Buenas Lecturas', route: '/Goodreads' },
        { name: 'Juegos y Concursos', route: '/Gamecontests' },
      ],
    },
    {
      name: 'GISP y Recursos',
      submenu: [
        { name: 'GISP', route: '/Toolsresources' },
        { name: 'Protección de Datos', route: '/data-protection' },
        { name: 'Ética Digital', route: '/Toolsresources' },
        { name: 'Capacitación', route: '/Toolsresources' },
      ],
    },
    { name: 'Archivos', route: '/Archives' },
  ],
  pt: [
    { name: 'Início', route: '/' },
    {
      name: 'Atividades',
      submenu: [
        { name: 'Boas Leituras', route: '/Goodreads' },
        { name: 'Jogos e Concursos', route: '/Gamecontests' },
      ],
    },
    {
      name: 'GISP e Recursos',
      submenu: [
        { name: 'GISP', route: '/Toolsresources' },
        { name: 'Proteção de Dados', route: '/data-protection' },
        { name: 'Ética Digital', route: '/Toolsresources' },
        { name: 'Treinamento', route: '/Toolsresources' },
      ],
    },
    { name: 'Arquivos', route: '/Archives' },
  ],
  zh: [
    { name: '首页', route: '/' },
    {
      name: '活动',
      submenu: [
        { name: '好书推荐', route: '/Goodreads' },
        { name: '游戏和竞赛', route: '/Gamecontests' },
      ],
    },
    {
      name: 'GISP 和资源',
      submenu: [
        { name: 'GISP', route: '/Toolsresources' },
        { name: '数据保护', route: '/data-protection' },
        { name: '数字伦理', route: '/Toolsresources' },
        { name: '培训', route: '/Toolsresources' },
      ],
    },
    { name: '档案', route: '/Archives' },
  ],
};

export default menuNames;
