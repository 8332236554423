/* eslint-disable prefer-const */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface WeeksData {
  month_id: number;
  week: string;
  tip_title: string;
  tip_description: string;
  thumbnail: string;
  status: number;
  video: string;
  video_title: string;
  [key: string]: string | number;
}

interface TranslationData {
  week: string;
  tip_title: string;
  tip_description: string;
  video: string;
  video_title: string;
  [key: string]: unknown;
}

interface State {
  data: WeeksData;
  translations: {
    es?: TranslationData;
    zh?: TranslationData;
    pt?: TranslationData;
  };
}

const AddCalendarWeek: React.FC = () => {
  const initialState = {
    data: {
      week: '',
      month_id: 0,
      tip_title: '',
      tip_description: '',
      thumbnail: '',
      video_title: '',
      video: '',
      status: 0,
    },
    translations: {
      es: { week: '', tip_title: '', tip_description: '', video_title: '', video: '' },
      pt: { week: '', tip_title: '', tip_description: '', video_title: '', video: '' },
      zh: { week: '', tip_title: '', tip_description: '', video_title: '', video: '' },
    },
  };

  const { locale } = useContext(LocaleContext);
  const [weeksData, setWeeksData] = useState<State>(initialState);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const slug = `calender_weeks`;

  const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
    slug,
    initialState,
  );

  useEffect(() => {
    if (!id) {
      setLocale('en');
    }
  }, [id, setLocale]);

  const query = useAuthenticatedQuery(
    ['quiz', id],
    {
      url: `${fixed}admin/en/calender_weeks/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
      setWeeksData(query.data.data as State);
    }
  }, [query.data]);

  const isUpdateForm = Boolean(id);

  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['uupdate-weeks', 'PUT'] : ['add-weeks', 'POST'],
    (data) => ({
      url: `${fixed}admin/${locale}/calender_weeks${isUpdateForm ? `/${id}` : ''}`,
      method: isUpdateForm ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      data,
    }),
  );

  const {
    data: monthsData,
    error: monthError,
    isPending: monthIsPending,
  } = useAuthenticatedQuery(['fetch-monts', 'GET'], {
    url: `${fixed}admin/en/calender_months`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const {
    data: yearsData,
    error: yearsError,
    isPending: yearsPending,
  } = useAuthenticatedQuery<YearData>(['fetch-years', 'GET'], {
    url: `${fixed}admin/en/calender_years`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  interface MonthData {
    id: number;
    month: string;
    month_id: number;
    year_id: number;
  }

  interface YearData {
    [x: string]: any;
    id: number;
  }


  if (monthIsPending) return <AdminPageLoader />;
  if (monthError) return <div>Error: {monthError.message}</div>;
  if (monthsData && !monthsData.success) return <AdminInternalError />;

  if (yearsPending) return <AdminPageLoader />;
  if (yearsError) return <div>Error: {yearsError.message}</div>;
  if (yearsData && !yearsData.success) return <AdminInternalError />;

  console.log('yearsData', yearsData);

  if (translatePending) return <AdminPageLoader />;
  if (errorTranslate) return <div>Error: {errorTranslate.message}</div>;
  if (translateData && !translateData.success) return <AdminInternalError />;

  const handleChange = (event: { target: { name: string; value: string | number; checked?: boolean } }) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? 1 : 0;
    }

    if (translateData) {
      const translatableFields = translateData.data;

      setWeeksData((prevState) => {
        let newData = prevState.data;
        let newTranslations = prevState.translations;

        if (locale !== 'en' && translatableFields.includes(name)) {
          newTranslations = {
            ...prevState.translations,
            [locale as keyof typeof prevState.translations]: {
              ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
              [name]: value,
            },
          };
        } else {
          newData = {
            ...prevState.data,
            [name]: value,
          };
        }

        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const data = { ...weeksData };

    if (data) {
      delete data.translations;
    }

    appendToFormData(formData, data, 'data');

    if (weeksData?.translations) {
      Object.entries(weeksData.translations).forEach(([localeKey, translation]) => {
        appendToFormData(formData, translation, `translations[${localeKey}]`);
      });
    }

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/calendar/weeks');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetLocale = (newLocale: string) => {
    const isNewOption = Object.keys(weeksData.data).length === 0;

    if (isNewOption) {
      setLocale('en');
    } else {
      if (locale === 'en' && newLocale !== 'en') {
        for (const key in weeksData.data) {
          // Check if the key is one of the excluded fields
          if (['tip_title', 'tip_description', 'thumbnail', 'video_title'].includes(key)) {
            continue; // Skip the current iteration and move to the next key
          }

          if (
            weeksData.data[key] === undefined ||
            weeksData.data[key] === '' ||
            (weeksData.data[key] === 0 && key !== 'status')
          ) {
            toast.error(`Please fill in the ${key} field first`);
            return;
          }
        }
      }

      setLocale(newLocale);
    }
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit Week' : 'Add Week'} </Typography>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <InputLabel id="year-label">
              Week <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              placeholder="Add Week"
              size="small"
              name="week"
              value={
                locale === 'en'
                  ? weeksData.data.week
                  : weeksData.translations[locale as keyof State['translations']]?.week || ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('week')}
              required={locale === 'en'}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">Month</InputLabel>

            <Select
              fullWidth
              labelId="year-label"
              name="month_id"
              size="small"
              value={
                weeksData?.data?.month_id && weeksData?.data?.month_id !== 0
                  ? weeksData?.data?.month_id?.toString()
                  : ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('month_id')}
              required={locale === 'en'}
            >
              {(monthsData.data as MonthData[]).map((month: MonthData) => {
                const year = yearsData.data.find((year: YearData) => year.id === month.year_id);
                return (
                  <MenuItem key={month.id} value={month.id.toString()}>
                    {month.month} {year ? `(${year.year})` : ''}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">
              Tip Title <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              placeholder="Add Tip Title"
              size="small"
              name="tip_title"
              value={
                locale === 'en'
                  ? weeksData.data.tip_title
                  : weeksData.translations[locale as keyof State['translations']]?.tip_title || ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('tip_title')}
              // required={locale === 'en'}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">
              Tip Description <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              placeholder="Add Tip Description"
              size="small"
              name="tip_description"
              value={
                locale === 'en'
                  ? weeksData.data.tip_description
                  : weeksData.translations[locale as keyof State['translations']]?.tip_description || ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('tip_description')}
              // required={locale === 'en'}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">Thumbnail </InputLabel>
            <TextField
              placeholder="Add Thumbnail"
              size="small"
              name="thumbnail"
              value={weeksData.data.thumbnail}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">
              Video Title <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              placeholder="Add video title"
              size="small"
              name="video_title"
              value={
                locale === 'en'
                  ? weeksData.data.video_title
                  : weeksData.translations[locale as keyof State['translations']]?.video_title || ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('video_title')}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">
              video <Typography component="sup">{locale.toUpperCase()}</Typography>{' '}
            </InputLabel>

            <TextField
              placeholder="Add video"
              size="small"
              name="video"
              value={
                locale === 'en'
                  ? weeksData.data.video
                  : weeksData.translations[locale as keyof State['translations']]?.video || ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('video')}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="status-label">Status</InputLabel>
            <Grid container alignItems="center">
              <Typography>Inactive</Typography>
              <Switch
                id="status-label"
                name="status"
                onChange={handleChange}
                checked={Boolean(weeksData.data.status)}
                disabled={locale !== 'en'}
              />
              <Typography>Active</Typography>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AdminWrapper>
  );
};

export default AddCalendarWeek;
