import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getCommonColumns } from 'csam/admin/components/CommonColumns';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';


const DataProtectionGoodReads = () => {
    const { locale, setLocale } = useContext(LocaleContext);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
  
    const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-data-protection-good-reads'], (ids) => ({
      url: `${fixed}admin/${locale}/data_protection_good_reads`,
      method: 'DELETE',
      data: { ids },
    }));
  
    const queryClient = useQueryClient();
    const handleDelete = (ids?: string[]) => {
      const idsToDelete = ids || selectedIds;
      deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
        onSuccess: () => {
          toast.success(`Record Deleted Successfully`);
          queryClient.invalidateQueries({
            queryKey: ['DatapProGoodReadsPage', 'DataProGoodReadsData', locale],
          });
        },
      });
    };
  
    const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'Id',
        filterable: false,
        width: 40,
        renderCell: (params) => params.value,
      },
  
      {
        field: 'status',
        headerName: 'Status',
        width: 80,
        renderCell: (params) => (Number(params.value) === 1 ? 'Active' : 'Inactive'),
      },
      {
        field: 'title',
        headerName: 'Title',
        width: 200,
        renderCell: (params) => (params.row.title ? params.row.title : 'N/A'),
      },
  
      { field: 'link', headerName: 'link', width: 200 },
      ...getCommonColumns({ handleDelete }),
    ];
  
    interface TrainingsData {
      id: number;
      title: string;
      link: string;
      created_at: string;
      status: number;
    }
  
    const localeFromStorage = localStorage.getItem('locale') || 'en';
    const { isPending, error, data } = useAuthenticatedQuery<TrainingsData[]>(
      ['DatapProGoodReadsPage', 'DataProGoodReadsData', locale],
      {
        url: `${fixed}admin/${locale || localeFromStorage}/data_protection_good_reads`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (isPending) return <AdminPageLoader />;
    if (error) return <div>Error: {error.message}</div>;
    if (data && !data.success) return <AdminInternalError />;
  
    return (
      <AdminWrapper>
          <AdminPageHeader
            title="Data Protection Good Reads"
            locale={locale}
            setLocale={setLocale}
            createLink="create"
            enableBulkDelete
          />
          <AdminDataGrid<TrainingsData>
            data={data}
            columns={columns}
            setSelectedIds={setSelectedIds}
          />
      </AdminWrapper>
    );
}

export default DataProtectionGoodReads