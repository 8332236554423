import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Input,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import React, { useState } from 'react';

interface CardData {
  title: string;
  image: string;
  hoveredImage: string;
  link: string;
  status: boolean;
}

interface HomeContent {
  heading: string;
  description: string;
  cards: CardData[];
}

interface Translation {
  homeContent: HomeContent;
}

interface Data {
  background_type: string;
  status: boolean;
  year_id: number;
  background_image: string;
  background_video: string;
  homeContent: HomeContent;
}

interface FormData {
  data: Data;
  translations: Record<string, Translation>;
}

const AddHomeContent: React.FC = () => {
  const [language, setLanguage] = useState('en');

  const initialCard = {
    title: '',
    image: '',
    hoveredImage: '',
    link: '',
    status: false,
  };

  const initialHomeContent = {
    heading: '',
    description: '',
    cards: [initialCard],
  };

  const initialFormData: FormData = {
    data: {
      background_type: '',
      status: false,
      year_id: 1,
      background_image: '',
      background_video: '',
      homeContent: initialHomeContent,
    },
    translations: {
      es: { homeContent: initialHomeContent },
      pt: { homeContent: initialHomeContent },
      zh: { homeContent: initialHomeContent },
    },
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);

  const handleChange =
    (field: string, isCardField = false, cardIndex?: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      setFormData((prevState) => {
        if (language === 'en') {
          if (isCardField && cardIndex !== undefined) {
            const updatedCards = prevState.data.homeContent.cards.map((card, index) =>
              index === cardIndex ? { ...card, [field]: value } : card,
            );
            return {
              ...prevState,
              data: {
                ...prevState.data,
                homeContent: {
                  ...prevState.data.homeContent,
                  cards: updatedCards,
                },
              },
            };
          } else if (field === 'background_image' || field === 'background_video') {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                [field]: value,
              },
            };
          } else {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                homeContent: {
                  ...prevState.data.homeContent,
                  [field]: value,
                },
              },
            };
          }
        } else {
          const updatedTranslations = {
            ...prevState.translations,
            [language]: {
              ...prevState.translations[language],
              homeContent: isCardField
                ? {
                    ...prevState.translations[language]?.homeContent,
                    cards: prevState.translations[language]?.homeContent?.cards.map((card, index) =>
                      index === cardIndex ? { ...card, [field]: value } : card,
                    ),
                  }
                : {
                    ...prevState.translations[language]?.homeContent,
                    [field]: value,
                  },
            },
          };

          if (field === 'background_image' || field === 'background_video') {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                [field]: value,
              },
            };
          } else {
            return {
              ...prevState,
              translations: updatedTranslations,
            };
          }
        }
      });
    };

  const handleAddCard = () => {
    setFormData((prevState) => {
      const updatedCards = [...prevState.data.homeContent.cards, initialCard];
      const updatedTranslations = Object.fromEntries(
        Object.entries(prevState.translations).map(([lang, translation]) => [
          lang,
          {
            ...translation,
            homeContent: {
              ...translation.homeContent,
              cards: [...(translation.homeContent.cards || []), initialCard],
            },
          },
        ]),
      );

      return {
        ...prevState,
        data: {
          ...prevState.data,
          homeContent: {
            ...prevState.data.homeContent,
            cards: updatedCards,
          },
        },
        translations: updatedTranslations,
      };
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6">Add Calendar Year</Typography>
        </Box>
        <Box>
          <ButtonGroup variant="contained" aria-label="Basic button group">
            <Button onClick={() => setLanguage('en')}>EN</Button>
            <Button onClick={() => setLanguage('es')}>ES</Button>
            <Button onClick={() => setLanguage('pt')}>PT</Button>
            <Button onClick={() => setLanguage('zh')}>ZH</Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <Box className="form-group">
                  <InputLabel id="label">
                    Page Title{' '}
                    <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>{language.toUpperCase()}</Typography>
                  </InputLabel>
                  <TextField
                    name="pageTitle"
                    size="small"
                    placeholder="Page Title"
                    type="text"
                    onChange={handleChange('heading')}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Box className="form-group">
                  <InputLabel id="label">
                    Page Description{' '}
                    <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>{language.toUpperCase()}</Typography>
                  </InputLabel>
                  <TextField
                    onChange={handleChange('description')}
                    name="pageDescription"
                    size="small"
                    placeholder="Page Description"
                    type="text"
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item sm={4}>
                <Box className="form-group">
                  <InputLabel>
                    Background Image{' '}
                    <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>{language.toUpperCase()}</Typography>
                  </InputLabel>
                  <Input type="file" fullWidth onChange={handleChange('background_image')} />
                </Box>
              </Grid>

              <Grid item sm={4}>
                <Box className="form-group">
                  <InputLabel>
                    Background Video{' '}
                    <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>{language.toUpperCase()}</Typography>
                  </InputLabel>
                  <Input type="file" fullWidth onChange={handleChange('background_video')} />
                </Box>
              </Grid>
            </Grid>

            {formData.data.homeContent.cards.map((card, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item sm={4}>
                  <Box className="form-group">
                    <InputLabel>
                      {`Card ${index + 1} Title `}
                      <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>
                        {language.toUpperCase()}
                      </Typography>
                    </InputLabel>
                    <TextField
                      size="small"
                      placeholder="Card Title"
                      type="text"
                      fullWidth
                      name="title"
                      onChange={handleChange('title', true, index)}
                    />
                  </Box>
                </Grid>

                <Grid item sm={4}>
                  <Box className="form-group">
                    <InputLabel>
                      {`Card ${index + 1} Image `}
                      <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>
                        {language.toUpperCase()}
                      </Typography>
                    </InputLabel>
                    <Input type="file" fullWidth onChange={handleChange('image', true, index)} />
                  </Box>
                </Grid>

                <Grid item sm={4}>
                  <Box className="form-group">
                    <InputLabel>
                      {`Card ${index + 1} Hover Image `}
                      <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>
                        {language.toUpperCase()}
                      </Typography>
                    </InputLabel>
                    <Input type="file" fullWidth onChange={handleChange('hoveredImage', true, index)} />
                  </Box>
                </Grid>

                <Grid item sm={4}>
                  <Box className="form-group">
                    <InputLabel>
                      {`Card ${index + 1} Page Link `}
                      <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>
                        {language.toUpperCase()}
                      </Typography>
                    </InputLabel>
                    <TextField
                      size="small"
                      placeholder="Page Link"
                      type="text"
                      fullWidth
                      name="title"
                      onChange={handleChange('link', true, index)}
                    />
                  </Box>
                </Grid>

                <Grid item sm={4}>
                  <Box className="form-group">
                    <InputLabel>
                      Status{' '}
                      <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>
                        {language.toUpperCase()}
                      </Typography>
                    </InputLabel>
                    <Checkbox onChange={handleChange('status', true, index)} />
                  </Box>
                </Grid>
              </Grid>
            ))}
            {formData.data.homeContent.cards.length < 5 && (
              <Grid item sm={12}>
                <Box className="form-group">
                  <Button variant="contained" onClick={handleAddCard}>
                    Add Card
                  </Button>
                </Box>
              </Grid>
            )}

            <Button type="submit" sx={{ color: '#fff', lineHeight: '26px' }} variant="contained" color="success">
              Save
            </Button>
          </form>
        </CardContent>
      </Card>
    </AdminWrapper>
  );
};

export default AddHomeContent;
