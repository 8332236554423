import { Box, Button, Typography } from '@mui/material';
import Videobg from 'csam/assets/video/bg_video.mp4';
import errorImg from 'csam/images/error.png';
import React from 'react';
import { Link } from 'react-router-dom';

const AdminInternalError: React.FC = () => (
  <Box className="errorWrapper adminErrorWrapper">
    <video autoPlay loop muted className="errorBgVideo">
      <source src={Videobg} type="video/mp4" />
    </video>
    <Box className="errorContent">
      <img className="errorIcon" src={errorImg} alt="" />
      <Typography variant="h1">Internal Server Error</Typography>
      <Typography variant="body1">We apologize for the inconvenience. Our team is working to fix the issue.</Typography>
      <Button variant="contained" component={Link} to="/admin" sx={{ mt: 2 }}>
        Back to Home
      </Button>{' '}
    </Box>
  </Box>
);

export default AdminInternalError;
