import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';


interface TrainingCardProps {
  training: {
    id: number;
    image: string;
    title: string;
    link: string;
  };
  getImage: string;
}

const TrainingCard: React.FC<TrainingCardProps> = ({ training, getImage }) => (
  <Grid key={training.id} item xs={12} md={3} className="flex-item">
    <Box className="card_news">
      <Box className="card_item">
        <img src={`${getImage}data_protection_tranings/${training.image}`} alt="Trainings" />
          <Link className="arrow_external" to={training.link} target="_blank" rel="noopener noreferrer">
            <img src="/images_public/folder.png" alt="Arrow" />
          </Link>
        <Box className="news_contnt mt-2">
          <Typography className="py-3" component="h4">
            {training.title}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Grid>
);

export default TrainingCard;
