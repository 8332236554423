import { useAccount, useMsal } from '@azure/msal-react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';


const QuizResults: React.FC = () => {
    const { locale } = useContext(LocaleContext);
    const [selectedIds, setSelectedIds] = useState<string[]>([]); 

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const account = activeAccount ? useAccount(activeAccount) : null;
    console.log('account', account ? account.name : 'No account');

    const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-result'], (ids) => ({
      url: `${fixed}admin/${locale}/quiz_results`,
      method: 'DELETE',
      data: { ids },
    }));
  
    const queryClient = useQueryClient();
    
    const handleDelete = (ids?: string[]) => {
      const idsToDelete = ids || selectedIds;
      deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
        onSuccess: () => {
          toast.success(`Record Deleted Successfully`);
          queryClient.invalidateQueries({
            queryKey: ['QuizResultsPage', 'QuizResultsData', locale],
          });
        },
      });
    };
  
    const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'id',
        filterable: false,
        renderCell: (params) => params.value,
        width: 40,
      },
      { field: 'user_id', headerName: 'User Id', width: 80 },
      { field: 'quiz_id', headerName: 'Quiz Id', width: 80 },
      { field: 'total_points', headerName: 'Total Points', width: 100 },
      { field: 'total_questions', headerName: 'Total Questions', width: 180 },
      { field: 'correct_answers', headerName: 'Correct Answers', width: 180 },
      { field: 'incorrect_answers', headerName: 'Incorrect Answers', width: 180 },
      { field: 'percentage', headerName: 'percentage', width: 130 },
     
      {
        field: 'actions',
        headerName: 'Actions',
        width: 160,
        renderCell: (params) => (
          <>
            <Button
              variant="contained"
              sx={{ py: 1, px: 2, mr: 1, minWidth: 'fit-content' }}
              onClick={() => handleDelete([params.row.id])}
              color="error"
            >
              <DeleteOutlineIcon sx={{ fontSize: '18px' }} />
            </Button>
          </>
        ),
      },
    ];
  
    interface RestultsData {
      [x: string]: any;
      id: number;
      total_points: number;
      user_id: number;
      quiz_id: number;
      total_questions: number;
      correct_answers: number;
      incorrect_answers: number;
      percentage: number;
    }

    interface UserData {
      userId: number;
    }
  
  
    const localeFromStorage = localStorage.getItem('locale') || 'en';

    const { isPending, error, data } = useAuthenticatedQuery<RestultsData>(['QuizResultsPage', 'QuizResultsData', locale], {
      url: `${fixed}admin/${locale || localeFromStorage}/quiz_results`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    const { isPending: userPending, error: userError, data: userData } = useAuthenticatedQuery<UserData>(['currentuser', 'curentUserData'], {
      url: `${fixed}user/get-current-user`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    
    if (userPending) return <AdminPageLoader />;
    if (userError) return <div>Error: {userError.message}</div>;
    if (userData && !userData.success) return <AdminInternalError />;

    const userId = userData.data.userId;
    console.log('userId', userId)


    if (isPending) return <AdminPageLoader />;
    if (error) return <div>Error: {error.message}</div>;
    if (data && !data.success) return <AdminInternalError />;
    
    return (
      <AdminWrapper>
        <Box className="pageHeader">
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography variant="h6">Quiz Results  <span className='ms-4'>{account ? account.name: ''}</span> </Typography>
          </Box>
        </Box>
  
        <Box>
          {data && data.data ? (
            <DataGrid
            rows={data.data.filter((item: RestultsData) => item.user_id === userId) as unknown as readonly RestultsData[]}
              columns={columns}
              checkboxSelection
              onRowSelectionModelChange={(newSelection) => {
                console.log(newSelection);
                setSelectedIds(newSelection.map((id) => id.toString()) as string[]);
              }}
            />
          ) : (
            <AdminPageLoader />
          )}
        </Box>
      </AdminWrapper>
    );
}

export default QuizResults