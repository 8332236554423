import React from 'react';
import './TrainingPlatform.css';
import Logo from 'csam/images/logo_img.png';
import { Typography} from '@mui/material';
const LocalURL = '/SecurityTraining';
// const PROD_PATH = 'public/3rdParty/CyberSecurityStage';


const TrainingPlatformAs: React.FC = () => {
  return (
    <div className="pb-5 trainingsContent">
      <header className="trainingsHeader">
        <div className="brand-logo p-5">
          <img src={Logo} alt="CSAM" />
        </div>
        <Typography className="common_title mb-4" variant="commonTitle" component="h4"> WELCOME TO ABI 3rd PARTY LEARNING PLATFORM </Typography>
      </header>
      <div id="block">
        <fieldset id="one">
          <legend>Application Security Training</legend>
          <small className="d-block text-end">Choose a language</small>
          <a
            target="_blank"
            href={`${LocalURL}/v1_3_29Jun2023_English/story.html`}
          >
            <div id="language">English Module</div>
          </a>
          <a
            target="_blank"
            href={`${LocalURL}/Apr2024_Chinese/story.html`}
          >
            <div id="language">Chinese Module</div>
          </a>
          <a
            target="_blank"
            href={`${LocalURL}/Apr2024_Spanish/story.html`}
          >
            <div id="language">Spanish Module</div>
          </a>
          <a
            target="_blank"
            href={`${LocalURL}/Apr2024_Portuguese/story.html`}
          >
            <div id="language">Portuguese Module</div>
          </a>
        </fieldset>
      </div>
    </div>
  );
};

export default TrainingPlatformAs;