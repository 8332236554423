import Typography from '@mui/material/Typography';
import React from 'react';

const Footer: React.FC = () => (
  <footer>
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      CSAP {new Date().getFullYear()}.
    </Typography>
  </footer>
);

export default Footer;
