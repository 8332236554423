import { Box, Button, Card, CardContent, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
// import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddCalendarYear: React.FC = () => {
  interface YearData {
    year: string;
    status: number;
  }

  interface State {
    data: YearData;
  }

  const [yearData, setYearData] = React.useState<State>({
    data: { year: '', status: 0 },
  });

  const currentYear = 2022;
  const years = Array.from({ length: 20 }, (_, i) => currentYear + i);

  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const query = useAuthenticatedQuery(
    ['calendar-year', id],
    {
      url: `${fixed}admin/en/calender_years/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
      setYearData(query.data.data as State);
    }
  }, [query.data]);

  const { locale } = useContext(LocaleContext);
  const isUpdateForm = Boolean(id);
  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['update-calendar-year', 'PUT'] : ['add-calendar-year', 'POST'],
    (data) => ({
      url: `${fixed}admin/${locale}/calender_years${isUpdateForm ? `/${id}` : ''}`,
      method: isUpdateForm ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      data,
    }),
  );

  const handleChange = (event: { target: { name: string; value: string; checked?: boolean } }) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? '1' : '0';
    }

    setYearData((prevState) => {
      let newData = prevState.data;
      newData = {
        ...prevState.data,
        [name]: value,
      };

      return {
        data: newData,
      };
    });
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    const data = { ...yearData };

    appendToFormData(formData, data, 'data');

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/calendar/years');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // const handleSubmit = (event: { preventDefault: () => void }) => {
  //   event.preventDefault();
  //   mutation.mutate(yearData, {
  //     onSuccess: () => {
  //       toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
  //       setTimeout(() => {
  //         navigate('/admin/calendar/years');
  //       }, 1000);
  //     },
  //     onError: () => {
  //       toast.error(`An error occurred while saving the data.`);
  //     },
  //   });
  // };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit Calender Year' : 'Add Calender Year'} </Typography>
        </Box>
        {/* <Box>
          <LocaleButtonGroup currentLocale={locale} setLocale={setLocale} />
        </Box> */}
      </Box>
      <Card>
        <CardContent>
          <Box component="form" onSubmit={handleSubmit}>
            <Box className="form-group">
              <InputLabel>
                Add Year
                <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>{locale.toUpperCase()}</Typography>
              </InputLabel>
              <Select
                labelId="label"
                id="select"
                size="small"
                name="year"
                value={yearData.data.year || ''}
                onChange={handleChange}
                fullWidth
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year.toString()}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box className="form-group">
              <InputLabel>
                Status
                <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>{locale.toUpperCase()}</Typography>
              </InputLabel>
              <Switch id="status-label" name="status" onChange={handleChange} checked={Boolean(yearData.data.status)} />
            </Box>

            <Button type="submit" sx={{ color: '#fff', lineHeight: '26px' }} variant="contained" color="success">
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </AdminWrapper>
  );
};
export default AddCalendarYear;
