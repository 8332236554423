import { useMsal } from '@azure/msal-react';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Drawer, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { logout } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import Leaderboard from 'csam/images/leaderboard.png';
import Logo from 'csam/images/logo_img.png';
import menuNames from 'csam/translations/MenuNames';
import LogoutIcon from '@mui/icons-material/Logout';
import 'csam/App.css';
import * as React from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

const Headerdark: React.FC = () => {
  const [currentLocale, setCurrentLocale] = React.useState('en');
  const msalDetails = useMsal();
  const { setLocale } = React.useContext(LocaleContext);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { locale: urlLocale } = useParams();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [languageAnchorEl, setLanguageAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMenuName, setOpenMenuName] = React.useState<string | null>(null);

  const handleClick = (pageName: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    if (openMenuName === pageName) {
      setOpenMenuName(null);
      setMenuAnchorEl(null);
    } else {
      setOpenMenuName(pageName);
      setMenuAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setOpenMenuName(null);
    setMenuAnchorEl(null);
  };

  const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLanguageAnchorEl(null);
  };

  const languages = [
    { code: 'en', label: 'EN' },
    { code: 'es', label: 'ES' },
    { code: 'pt', label: 'PT' },
    { code: 'zh', label: 'ZH' },
  ];

  React.useEffect(() => {
    let locale = urlLocale || localStorage.getItem('locale') || 'en';
    if (!['en', 'es', 'pt', 'zh'].includes(locale)) {
      locale = 'en';
    }
    setCurrentLocale(locale);
    localStorage.setItem('locale', locale);
  }, [urlLocale]);

  const handleLocaleChange = (locale: string) => {
    if (!['en', 'es', 'pt', 'zh'].includes(locale)) {
      locale = 'en';
    }
    setLocale(locale);
    setCurrentLocale(locale);
    localStorage.setItem('locale', locale);
    handleClose();
    const path = location.pathname.replace(/\/(en|es|pt|zh)/, '');
    navigate(`/${locale}${path}`);
  };

  const locale = localStorage.getItem('locale') || 'en';
  const pages = menuNames[locale];

  return (
    <AppBar className="headerTop" data-testid="AppBar">
      <Toolbar>
        <Link to="/" data-testid="logo-link">
          <img className="logo_width" src={Logo} alt="" data-testid="logo" />
        </Link>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' }, justifyContent: 'end' }}>
          <Button onClick={toggleDrawer(true)} sx={{ justifyContent: 'end' }} data-testid="MenuIcon">
            <MenuIcon />
          </Button>
          <Drawer open={open} onClose={toggleDrawer(false)} data-testid="Drawer">
            <Box sx={{ width: '220px', height: '100%', backgroundColor: '#000000' }}>
              {pages?.map((page, index) => (
                <Link
                  to={`/${locale}${page.route}`}
                  className="header_link"
                  key={page.name}
                  data-testid={`header-link-${index}`}
                >
                  <MenuItem data-testid={`menu-item-${index}`}>
                    <Typography sx={{ color: '#fff' }} textAlign="center" data-testid={`menu-item-name-${index}`}>
                      {page.name}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Box>
          </Drawer>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' }, justifyContent: 'center', pl: 5 }}>
          {pages?.map((page) => {
            const isMenuOpen = openMenuName === page.name;
            return (
              <div key={page.name}>
                {page.submenu ? (
                  <>
                    <Button
                      aria-controls={isMenuOpen ? 'menu-' + page.name : undefined}
                      aria-haspopup="true"
                      aria-expanded={isMenuOpen ? 'true' : undefined}
                      onClick={handleClick(page.name)}
                      sx={{ color: '#fff', textTransform: 'none' }}
                      className="dropDownHeader"
                    >
                      {page.name}
                    </Button>
                    <Menu
                      id={'menu-' + page.name}
                      disableScrollLock
                      anchorEl={isMenuOpen ? menuAnchorEl : null}
                      keepMounted
                      open={isMenuOpen}
                      onClose={handleMenuClose}
                    >
                      {page.submenu.map((subPage) => (
                        <MenuItem className="navBarDropdown" onClick={handleMenuClose} key={subPage.name}>
                          <NavLink
                            to={`/${locale}${subPage.route}`}
                            className="header_link"
                            end={subPage.route === '/'}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {subPage.name}
                          </NavLink>
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                ) : (
                  <NavLink to={`/${locale}${page.route}`} className="header_link" end={page.route === '/'}>
                    <MenuItem>
                      <Typography sx={{ color: '#fff' }} textAlign="center">
                        {page.name}
                      </Typography>
                    </MenuItem>
                  </NavLink>
                )}
              </div>
            );
          })}
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Box className="d-flex align-items-center">
            <Button className="language_change" onClick={handleLanguageClick} sx={{ mr: 2 }}>
              {currentLocale.toUpperCase()}
            </Button>
            <Menu
              id="language-menu"
              anchorEl={languageAnchorEl}
              open={Boolean(languageAnchorEl)}
              onClose={handleClose}
              disableScrollLock
              MenuListProps={{
                'aria-labelledby': 'language-button',
              }}
            >
              {languages.map((language) => (
                <MenuItem
                  sx={{ color: '#fff', background: '#00000047' }}
                  onClick={() => handleLocaleChange(language.code)}
                  key={language.code}
                >
                  {language.label}
                </MenuItem>
              ))}
            </Menu>

            <Box className="flex-item">
              <NavLink className="nav-link" to={`/${locale}/Leaderboard`}>
                <img src={Leaderboard} alt="" />
              </NavLink>
            </Box>

            <Box className="flex-item hide-sm">
              <Button type="button" onClick={() => logout(msalDetails)} title="Log Out">
                {' '}
                <LogoutIcon />{' '}
              </Button>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Headerdark;
