const FeedbackModelTranslatiions = {
  en: {
    title: 'Feedback Form',
    subTitle: 'How was your experience using our Cyber Security website so far?',
    leastSatisfied: 'Least Satisfied',
    mostSatisfied: 'Most Satisfied',
    addComments: 'Add comments...',
    submit: 'Submit',
  },
  es: {
    title: 'Formulario de retroalimentación',
    subTitle: '¿Cómo ha sido tu experiencia utilizando nuestro sitio web de ciberseguridad hasta ahora?',
    leastSatisfied: 'Menos satisfecho',
    mostSatisfied: 'Más satisfecho',
    addComments: 'Agregar comentarios...',
    submit: 'Enviar',
  },
  pt: {
    title: 'Formulário de feedback',
    subTitle: 'Como foi sua experiência usando nosso site de segurança cibernética até agora?',
    leastSatisfied: 'Menos satisfeito',
    mostSatisfied: 'Mais satisfeito',
    addComments: 'Adicionar comentários...',
    submit: 'Enviar',
  },
  zh: {
    title: '反馈表',
    subTitle: '您使用我们的网络安全网站的体验如何？',
    leastSatisfied: '最不满意',
    mostSatisfied: '最满意',
    addComments: '添加评论...',
    submit: '提交',
  },
};

export default FeedbackModelTranslatiions;
