import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface WeekData {
  week: string;
  week_title: string;
  status: boolean;
  year_id: number;
  [key: string]: string | number | boolean | null;
}

interface TranslationData {
  week_title: string;
  week: string;
  [key: string]: unknown;
}

interface State {
  data: WeekData;
  translations: {
    es: TranslationData;
    pt: TranslationData;
    zh: TranslationData;
  };
}

const AddOctWeeks = () => {
  const initialState = {
    data: {
      week: '',
      week_title: '',
      status: false,
      year_id: 0,
    },
    translations: {
      es: { week_title: '', week: '' },
      pt: { week_title: '', week: '' },
      zh: { week_title: '', week: '' },
    },
  };

  const [weekData, setWeekData] = useState<State>(initialState);
  const { locale } = useContext(LocaleContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const slug = `october_weeks`;

  const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
    slug,
    initialState,
  );

  useEffect(() => {
    if (!id) {
      setLocale('en');
    }
  }, [id, setLocale]);

  const query = useAuthenticatedQuery(
    ['october_weeks', id],
    {
      url: `${fixed}admin/en/october_weeks/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
      setWeekData(query.data.data as State);
    }
  }, [query.data]);

  const isUpdateForm = Boolean(id);

  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['update-week', 'PUT'] : ['add-week', 'POST'],

    (data) => {
      const headers = { 'Content-Type': 'application/json' };

      return {
        url: `${fixed}admin/${locale}/october_weeks${isUpdateForm ? `/${id}` : ''}`,
        method: isUpdateForm ? 'PUT' : 'POST',
        headers,
        data,
      };
    },
  );

  const handleChange = (event: { target: { name: string; value: string | number | boolean | null; checked?: boolean } }) => {
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? true : false;
    }

    setWeekData((prevState) => {
      let newData = prevState.data;
      let newTranslations = prevState.translations;

      if (locale !== 'en' && translateData?.data.includes(name)) {
        newTranslations = {
          ...prevState.translations,
          [locale as keyof typeof prevState.translations]: {
            ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
            [name]: value,
          },
        };
      } else {
        newData = {
          ...newData,
          [name]: value,
        };
      }

      return {
        data: newData,
        translations: newTranslations,
      };
    });
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (value instanceof File) {
        formData.append(fullKey, value);
      } else if (typeof value === 'object' && value !== null) {
        appendToFormData(formData, value as Record<string, unknown>, fullKey);
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    console.log('weekData', weekData);
    const data = { ...weekData };

    if (data) {
      delete data?.translations;
    }

    appendToFormData(formData, data, 'data');

    if (weekData?.translations) {
      Object.entries(weekData.translations).forEach(([localeKey, translation]) => {
        appendToFormData(formData, translation, `translations[${localeKey}]`);
      });
    }

    try {
      await mutation.mutateAsync(data, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/october-weeks');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleSetLocale = (newLocale: string) => {
    if (locale === 'en' && newLocale !== 'en') {
      for (const key in weekData.data) {
        if (
          weekData.data[key] === undefined ||
          weekData.data[key] === '' ||
          (weekData.data[key] === 0 && key !== 'status')
        ) {
          toast.error(`Please fill in the ${key} field first`);
          return;
        }
      }
    }

    setLocale(newLocale);
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit Week' : 'Add Week'} </Typography>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Box className="form-group">
              <InputLabel id="week-label">
                Week <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <Select
                labelId="week-label"
                name="week"
                value={
                  locale === 'en'
                    ? weekData.data.week
                    : weekData.translations[locale as keyof State['translations']]?.week || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('week')}
                required={locale === 'en'}
                fullWidth
              >
                <MenuItem value="Week_1">Week 1</MenuItem>
                <MenuItem value="Week_2">Week 2</MenuItem>
                <MenuItem value="Week_3">Week 3</MenuItem>
                <MenuItem value="Week_4">Week 4</MenuItem>
              </Select>
            </Box>
          </Grid>

          <Grid item sm={6}>
            <Box className="form-group">
              <InputLabel id="weekTitle-label">
                Week Title <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Week Title"
                size="small"
                name="week_title"
                value={
                  locale === 'en'
                    ? weekData.data.week_title
                    : weekData.translations[locale as keyof State['translations']]?.week_title || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('week_title')}
                required={locale === 'en'}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item sm={6}>
            <Box className="form-group">
              <InputLabel id="status-label">Status</InputLabel>
              <Grid container alignItems="center">
                <Typography>Inactive</Typography>
                <Switch
                  id="status-label"
                  name="status"
                  onChange={handleChange}
                  checked={Boolean(weekData.data.status)}
                  disabled={locale !== 'en'}
                />
                <Typography>Active</Typography>
              </Grid>
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AdminWrapper>
  );
};

export default AddOctWeeks;