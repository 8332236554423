const DataProtectionTranslations = {
  en: {
    subHeading: 'Data Protection',
    heading1: '“We protect data from corruption, compromise and loss”​',
    heading2: 'Trainings',
    heading3: 'Meet our team​',
    heading4: 'Good Reads',
    contactText: 'Contact: @globaldataprotection for any queries or request',
    discovery: 'Data Discovery:',
    discovery2: 'Importance of Data Discovery​',
    classification: 'Data Classification: ',
    classification2: 'Importance of Data Classification',
    prevention: 'Data Loss Prevention:',
    prevention2: 'Importance of DLP',
  },
  es: {
    subHeading: 'Datos Protección',
    heading1: '“Protegemos los datos contra la corrupción, el compromiso y la pérdida”​',
    heading2: 'Entrenamientos',
    heading3: 'Conoce a nuestro equipo',
    heading4: 'Buenas Lecturas',
    contactText: 'Contacto: @globaldataprotection para cualquier consulta o solicitud',
    discovery: 'Descubrimiento de Datos:',
    discovery2: 'Importancia del Descubrimiento de Datos​',
    classification: 'Clasificación de Datos: ',
    classification2: 'Importancia de la Clasificación de Datos',
    prevention: 'Prevención de Pérdida de Datos:',
    prevention2: 'Importancia de la Prevención de Pérdida de Datos',
  },
  pt: {
    subHeading: 'Dados Proteção',
    heading1: '“Nós protegemos os dados contra corrupção, comprometimento e perda”​',
    heading2: 'Treinamentos',
    heading3: 'Conheça nossa equipe',
    heading4: 'Boas Leituras',
    contactText: 'Contato: @globaldataprotection para qualquer dúvida ou solicitação',
    discovery: 'Descoberta de Dados:',
    discovery2: 'Importância da Descoberta de Dados​',
    classification: 'Classificação de Dados: ',
    classification2: 'Importância da Classificação de Dados',
    prevention: 'Prevenção de Perda de Dados:',
    prevention2: 'Importância da Prevenção de Perda de Dados',
  },
  zh: {
    subHeading: '数据 保护',
    heading1: '“我们保护数据免受损坏、泄漏和丢失”​',
    heading2: '培训',
    heading3: '认识我们的团队',
    heading4: '好书',
    contactText: '联系方式：@globaldataprotection 如有任何疑问或请求，请联系我们',
    discovery: '数据发现:',
    discovery2: '数据发现的重要性​',
    classification: '数据分类: ',
    classification2: '数据分类的重要性',
    prevention: '数据丢失预防:',
    prevention2: '数据丢失预防的重要性',
  },
};

export default DataProtectionTranslations;