import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getCommonColumns } from 'csam/admin/components/CommonColumns';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

const OctDays = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-Option'], (ids) => ({
    url: `${fixed}admin/${locale}/october_days`,
    method: 'DELETE',
    data: { ids },
  }));

  const queryClient = useQueryClient();

  const handleDelete = (ids?: string[]) => {
    const idsToDelete = ids || selectedIds;
    deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
      onSuccess: () => {
        toast.success(`Record Deleted Successfully`);
        queryClient.invalidateQueries({
          queryKey: ['OctDaysPage', 'OctDaysPageData', locale],
        });
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 40,
      renderCell: (params) => params.value,
    },
    {
      field: 'october_week_id',
      headerName: 'Week ID',
      width: 80,
      renderCell: (params) => params.value,
    },
    {
      field: 'day_name',
      headerName: 'Day Name',
      width: 100,
      renderCell: (params) => params.value,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      renderCell: (params) => (params.value ? 'Active' : 'Inactive'),
    },
    {
      field: 'video',
      headerName: 'Video',
      width: 200,
      renderCell: (params) => params.value,
    },
    {
      field: 'tip_title',
      headerName: 'Tip Title',
      width: 200,
      renderCell: (params) => params.value,
    },
    {
      field: 'tip_description',
      headerName: 'Tip Description',
      width: 300,
      renderCell: (params) => params.value,
    },
    ...getCommonColumns({ handleDelete }),
  ];

  interface DaysData {
    id: number;
    october_week_id: number;
    day: number;
    day_name: string;
    status: boolean;
    video: string;
    tip_title: string;
    tip_description: string;
    created_at: string;
    updated_at: string;
  }

  interface DaysResponse {
    success: boolean;
    data: DaysData[];
    translatableFields: string[];
  }

  const relations = ['october_week'];
  const relationsParam = relations.join(',');

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<DaysResponse[]>(['OctDaysPage', 'OctDaysPageData', locale], {
    url: `${fixed}admin/${locale || localeFromStorage}/october_days?relations=${relationsParam}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  return (
    <AdminWrapper>
      <AdminPageHeader
        title="October Days"
        locale={locale}
        setLocale={setLocale}
        createLink="create"
        enableBulkDelete
      />
      <AdminDataGrid<DaysResponse>
        data={data}
        columns={columns}
        setSelectedIds={setSelectedIds}
      />
    </AdminWrapper>
  );
};

export default OctDays;