import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface OptionData {
  status: number;
  points: number;
  question_id: number;
  option_text: string;
  [key: string]: string | number;
}

interface TranslationData {
  option_text: string;
  [key: string]: unknown;
}

interface State {
  data: OptionData;
  translations: {
    es: TranslationData;
    pt: TranslationData;
    zh: TranslationData;
  };
}

const AddOptions: React.FC = () => {
  const initialState = {
    data: { question_id: 0, option_text: '', points: 0, status: 0 },
    translations: {
      es: { option_text: '' },
      pt: { option_text: '' },
      zh: { option_text: '' },
    },
  };

  const { locale } = useContext(LocaleContext);
  const [optionsData, setOptionsData] = useState<State>(initialState);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const useQuery = () => new URLSearchParams(useLocation().search);

  const queryQuestion = useQuery();
  const questionIdFromUrl = queryQuestion.get('id');

  const slug = `questions_options`;

  const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
    slug,
    initialState,
  );

  useEffect(() => {
    if (!id) {
      setLocale('en');
    }
  }, [id, setLocale]);

  const query = useAuthenticatedQuery(
    ['option', id],
    {
      url: `${fixed}admin/en/questions_options/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
      setOptionsData(query.data.data as State);
    }
  }, [query.data]);

  const questionValue = useRef('');
  useEffect(() => {
    if (questionIdFromUrl) {
      questionValue.current = questionIdFromUrl;
      setOptionsData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          question_id: Number(questionIdFromUrl),
        },
      }));
    } else if (optionsData?.data?.question_id && optionsData?.data?.question_id !== 0) {
      questionValue.current = optionsData?.data?.question_id?.toString();
    }
  }, [questionIdFromUrl, optionsData]);

  const isUpdateForm = Boolean(id);

  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['uupdate-option', 'PUT'] : ['add-option', 'POST'],
    (data) => ({
      url: `${fixed}admin/${locale}/questions_options${isUpdateForm ? `/${id}` : ''}`,
      method: isUpdateForm ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      data,
    }),
  );

  const {
    data: questionsData,
    error: questionError,
    isPending: questionPending,
  } = useAuthenticatedQuery(['fetch-questions', 'GETQuestion'], {
    url: `${fixed}admin/en/quiz_questions`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  interface QuestionData {
    id: number;
    question_text: string;
  }

  if (translatePending) return <AdminPageLoader />;
  if (errorTranslate) return <div>Error: {errorTranslate.message}</div>;
  if (translateData && !translateData.success) return <AdminInternalError />;

  if (questionPending) return <AdminPageLoader />;
  if (questionError) return <div>Error: {questionError.message}</div>;
  if (questionsData && !questionsData.success) return <AdminInternalError />;

  const handleChange = (event: { target: { name: string; value: string | number; checked?: boolean } }) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? 1 : 0;
    }

    if (translateData) {
      const translatableFields = translateData.data;

      setOptionsData((prevState) => {
        let newData = prevState.data;
        let newTranslations = prevState.translations;

        if (locale !== 'en' && translatableFields.includes(name)) {
          newTranslations = {
            ...prevState.translations,
            [locale as keyof typeof prevState.translations]: {
              ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
              [name]: value,
            },
          };
        } else {
          newData = {
            ...prevState.data,
            [name]: value,
          };
        }

        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const data = { ...optionsData };

    if (data) {
      delete data.translations;
    }

    appendToFormData(formData, data, 'data');

    if (optionsData?.translations) {
      Object.entries(optionsData.translations).forEach(([localeKey, translation]) => {
        appendToFormData(formData, translation, `translations[${localeKey}]`);
      });
    }

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/options');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetLocale = (newLocale: string) => {
    const isNewOption = Object.keys(optionsData.data).length === 0;

    if (isNewOption) {
      setLocale('en');
    } else {
      if (locale === 'en' && newLocale !== 'en') {
        for (const key in optionsData.data) {
          if (
            optionsData.data[key] === undefined ||
            optionsData.data[key] === '' ||
            (optionsData.data[key] === 0 && key !== 'status')
          ) {
            toast.error(`Please fill in the ${key} field first`);
            return;
          }
        }
      }

      setLocale(newLocale);
    }
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit Option' : 'Add Option'} </Typography>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <InputLabel id="year-label">Select Questions</InputLabel>

            <Select
              fullWidth
              labelId="year-label"
              name="question_id"
              size="small"
              value={optionsData?.data?.question_id || ''}
              onChange={handleChange}
              disabled={disabledFields.includes('question_id')}
              required={locale === 'en'}
            >
              {(questionsData.data as QuestionData[]).map((question: QuestionData) => (
                <MenuItem key={question.id} value={question.id.toString()}>
                  {question.question_text}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item sm={6}>
            <div className="form-group">
              <InputLabel id="monthTitle-label">
                Option <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Option"
                size="small"
                name="option_text"
                value={
                  locale === 'en'
                    ? optionsData.data.option_text
                    : optionsData.translations[locale as keyof State['translations']]?.option_text || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('option_text')}
                required={locale === 'en'}
                fullWidth
              />
            </div>
          </Grid>

          <Grid item sm={6}>
            <div className="form-group">
              <InputLabel id="monthTitle-label">
                Points <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Points"
                size="small"
                name="points"
                type="number"
                value={
                  locale === 'en'
                    ? optionsData.data.points
                    : optionsData.translations[locale as keyof State['translations']]?.points || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('points')}
                fullWidth
              />
            </div>
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="status-label">Status</InputLabel>
            <Grid container alignItems="center">
              <Typography>Inactive</Typography>
              <Switch
                id="status-label"
                name="status"
                onChange={handleChange}
                checked={Boolean(optionsData.data.status)}
                disabled={locale !== 'en'}
              />
              <Typography>Active</Typography>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AdminWrapper>
  );
};

export default AddOptions;
