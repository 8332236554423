import { Box, Container, Grid, Typography } from '@mui/material';
import DataProtectionTranslations from 'csam/translations/DataProtectionTranslations';
import { getImage } from 'csam/utils/Constants';
import React from 'react';
import { Link } from 'react-router-dom';

interface OrgStructureProps {
  orgImage: string | null;
  goodReads: any;
}

const OrgStructure: React.FC<OrgStructureProps> = ({ orgImage, goodReads }) => {
  const localeFromStorage = localStorage.getItem('locale') ?? 'en';

  const getTranslation = (key: keyof (typeof DataProtectionTranslations)['en']) =>
    DataProtectionTranslations[localeFromStorage as keyof typeof DataProtectionTranslations]?.[key] ||
    DataProtectionTranslations.en[key];

  return (
    <Container sx={{ pt: 5 }}>
      <Box sx={{ color: '#fff' }}>
        <Typography className="common_title common_data-pro-title mt-5 mb-4" variant="commonTitle" component="h4">
          {getTranslation('heading4')}
        </Typography>

        <Box className="quick_tools">
          <Grid container spacing={4} sx={{ mt: 3 }}>
            {goodReads.map((item: any) => (
              <Grid item xs={12} md={3} className="flex-item" key={item.id}>
                <Box className="card_news">
                  <Box className="card_item">
                    <img src={`${getImage}data_protection_good_reads/${item.image}`} alt="Good Reads" />
                    <Link className="arrow_external" to={item.link} target="_blank" rel="noopener noreferrer">
                      <img src="/images_public/arrowh.png" alt="Arrow" />
                    </Link>
                    <Box className="news_contnt mt-2">
                      <Typography className="py-3" component="h4">
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* <Typography sx={{ fontSize: '16px', marginBottom: '10px' }} variant="body1">
          {getTranslation('discovery')}{' '}
          <Link
            to="https://ivaluegroup.com/en-in/resources/blogs/the-importance-of-data-discovery-in-protecting-sensitive-information/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {getTranslation('discovery2')}
          </Link>
          ​
        </Typography>

        <Typography sx={{ fontSize: '16px', marginBottom: '10px' }} variant="body1">
          {getTranslation('classification')}{' '}
          <Link
            to="https://www.matillion.com/blog/the-importance-of-data-classification-in-cloud-security#:~:text=Data%20classification%20is%20critical%20for,storage%20rules%2C%20and%20retention%20policies"
            target="_blank"
            rel="noopener noreferrer"
          >
            {getTranslation('classification2')}
          </Link>
          ​
        </Typography>

        <Typography sx={{ fontSize: '16px' }} variant="body1">
          {getTranslation('prevention')}{' '}
          <Link
            to="https://spanning.com/blog/data-loss-prevention-dlp/#:~:text=Protects%20sensitive%20data%3A%20LP%20solutions,source%20code%20and%20trade%20secrets."
            target="_blank"
            rel="noopener noreferrer"
          >
            {getTranslation('prevention2')}
          </Link>
          ​
        </Typography> */}
      </Box>

      <Typography className="common_title common_data-pro-title mt-5 pt-3" variant="commonTitle" component="h4">
        {getTranslation('heading3')}
      </Typography>

      <Box className="quick_tools">
        {orgImage ? (
          <img
            src={`${getImage}organization_structure/${orgImage}`}
            className="img-responsive mx-auto d-block mt-5"
            style={{ width: '90%' }}
            alt="Organization Structure"
          />
        ) : (
          <p className='text-center mt-3'>No image available</p>
        )}

        {/* <img src="/images_public/org-structure.png" className="img-responsive" alt="Org Structure" /> */}
        <Typography sx={{ fontSize: '20px', marginBottom: '10px', textAlign: 'center', mt: 3 }} variant="body1">
          {getTranslation('contactText')}
        </Typography>
      </Box>
    </Container>
  );
};

export default OrgStructure;
