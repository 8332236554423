import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';

const OctPhishingKeyFetures: React.FC = () => {
  return (
    <Box className="keyFeturesPhishing">
      <Container sx={{ pb: 4, pt: 4, textAlign: 'center' }}>
        <Typography variant="h6">Know a lot about phishing?</Typography>
        <Typography variant="h6">
          You may be good at detecting phishing emails, but can you create the perfect bait to hook users through
          phishing mail?
        </Typography>
        <Typography variant="h6">The choice of recipe is yours…</Typography>
      </Container>

      <Container sx={{ pb: 4, pt: 4, textAlign: 'center' }}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Box className="pledgeCard">
              <img src="/images_public/email-icon-1.png" alt="Pledge Icon" />
              <Typography variant="body1" mt={4}>
                your phishing email can be a scam that relies on user’s fear and urgency or you may choose to pressurize
                them to make a payment or manipulate the user to divulge sensitive information.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={4} xs={12}>
            <Box className="pledgeCard">
              <img src="/images_public/email-icon-2.png" alt="Pledge Icon" />
              <Typography variant="body1" mt={4}>
                Travel to the dark side and craft a seemingly genuine phishing email and stand a chance to win
                attractive prizes.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={4} xs={12}>
            <Box className="pledgeCard">
              <img src="/images_public/email-icon-3.png" alt="Pledge Icon" />
              <Typography variant="body1" mt={4}>
                Play the Phishing Bait game to understand Phishing indicators. Make sure to include as many tell-tale
                signs of phishing as you can, the more sophisticated the better.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ pb: 4, pt: 4, textAlign: 'center' }}>
        <Box className="do-your-part">
          <Typography component="span">#DoYourPart #BeCyberSmart</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default OctPhishingKeyFetures;
