import { Box, Button, ButtonGroup } from '@mui/material';
import React from 'react';

interface LocaleButtonGroupProps {
  currentLocale: string;
  setLocale: (locale: string) => void;
}

interface LocaleButtonProps {
  locale: string;
  currentLocale: string;
  setLocale: (locale: string) => void;
}

const LocaleButton: React.FC<LocaleButtonProps> = ({ locale, currentLocale, setLocale }) => (
  <Button variant={currentLocale === locale ? 'contained' : 'outlined'} onClick={() => setLocale(locale)}>
    {locale.toUpperCase()}
  </Button>
);

const LocaleButtonGroup: React.FC<LocaleButtonGroupProps> = ({ currentLocale, setLocale }) => (
  <Box>
    <ButtonGroup variant="contained" aria-label="Basic button group">
      <LocaleButton locale="en" currentLocale={currentLocale} setLocale={setLocale} />
      <LocaleButton locale="es" currentLocale={currentLocale} setLocale={setLocale} />
      <LocaleButton locale="pt" currentLocale={currentLocale} setLocale={setLocale} />
      <LocaleButton locale="zh" currentLocale={currentLocale} setLocale={setLocale} />
    </ButtonGroup>
  </Box>
);

export default LocaleButtonGroup;
