import { Box, Typography, Button, InputLabel } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useState } from 'react';
import { CSVLink } from 'react-csv';
import { subMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

type DateRange = [Date | null, Date | null];

const NoRowsOverlay = () => {
  return <Box style={{ padding: 16, textAlign: 'center' }}>No data found</Box>;
};

const DataProtectionUserActivity: React.FC = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [, setSelectedIds] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const [triggerQuery, setTriggerQuery] = useState(false);

  const headers = [
    { label: 'Id', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Points', key: 'points' },
  ];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      renderCell: (params) => params.value,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params) => params.row.name || '-',
    },
    {
        field: 'zone',
        headerName: 'Zone',
        width: 100,
        renderCell: (params) => params.row.zone || '-',
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 350,
      renderCell: (params) => params.row.email || '-',
    },
    {
      field: 'activity_name',
      headerName: 'Activity Name',
      width: 300,
      renderCell: (params) => params.row.activity_name || '-',
    },
    {
      field: 'points',
      headerName: 'Points',
      width: 100,
      renderCell: (params) => params.value,
    },
  ];

  interface UserActivity {
    id: number;
    name: string;
    email: string;
    points: number;
  }

  const relations = ['activites', 'users'];
  const relationsParam = relations.join(',');

  const localeFromStorage = localStorage.getItem('locale') || 'en';

  const { isPending, error, data } = useAuthenticatedQuery<UserActivity>(
    ['UserActivityPage', 'userActivityData', locale],
    {
      url: `${fixed}admin/${locale || localeFromStorage}/user_activities?relations=${relationsParam}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  const {
    isPending: isActivityPending,
    error: activityError,
    data: activityData,
  } = useAuthenticatedQuery<UserActivity>(
    ['filterActivities', 'filterActivitiesData', dateRange],
    {
      url: `${fixed}activites/data-protection-activities`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: {
        startDate: dateRange[0],
        endDate: dateRange[1],
      },
    },
    {
      enabled: triggerQuery && dateRange[0] !== null && dateRange[1] !== null,
    },
  );

  if (triggerQuery && isActivityPending) return <AdminPageLoader />;
  if (activityError) return <div>Error: {activityError.message}</div>;
  if (activityData && !activityData.success) return <AdminInternalError />;

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;
  console.log('check data', data);

  const transformData = (data) => {
    if (!data || !Array.isArray(data.data)) {
      return [];
    }

    return data.data
      .filter((item) => {
        const activityName = item.activites ? item.activites.activity_name : '';
        return (
          activityName.endsWith('_data_protection_hover_activity') ||
          activityName.endsWith('_data_protection_click_activity')
        );
      })
      .map((item) => ({
        id: item.id,
        name: item.users ? item.users.name : '',
        email: item.users ? item.users.email : '',
        activity_name: item.activites ? item.activites.activity_name.replace(/[\d_]/g, ' ').trim() : '',
        points: item.points,
      }));
  };

  const transformActivityData = (activityData) => {
    if (!activityData || !Array.isArray(activityData.data)) {
      return [];
    }

    return activityData.data
      .filter((item) => {
        const activityName = item.activites ? item.activites.activity_name : '';
        return (
          activityName.endsWith('_data_protection_hover_activity') ||
          activityName.endsWith('_data_protection_click_activity')
        );
      })
      .map((item) => ({
        id: item.id,
        name: item.users ? item.users.name : '',
        email: item.users ? item.users.email : '',
        activity_name: item.activites ? item.activites.activity_name.replace(/[\d_]/g, ' ').trim() : '',
        points: item.points,
      }));
  };

  const csvData = activityData ? transformActivityData(activityData) : transformData(data);

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6">User Activities</Typography>
          <Button
            type="button"
            sx={{ color: '#fff', lineHeight: '26px' }}
            variant="contained"
            size="small"
            color="success"
            className="download-csv-button"
            title="Download CSV"
          >
            <CSVLink data={csvData} headers={headers}>
              <CloudDownloadIcon sx={{ fontSize: '18px' }} /> CSV
            </CSVLink>
          </Button>
        </Box>
        <Box className="d-flex gap-3 align-items-center">
          <Box>
            <InputLabel id="year-label">Select Date to filter activities</InputLabel>
            <DatePicker
              selected={dateRange[0] || undefined}
              onChange={(dates) => {
                const [start, end] = dates as [Date | null, Date | null];
                setDateRange([start, end]);
                if (start !== null && end !== null) {
                  setTriggerQuery(true);
                } else {
                  setTriggerQuery(false);
                }
              }}
              startDate={dateRange[0] || undefined}
              endDate={dateRange[1] || undefined}
              selectsRange
              monthsShown={2}
              minDate={subMonths(new Date(), 48)}
              maxDate={new Date()}
              placeholderText="Select date range"
            />
          </Box>
          <LocaleButtonGroup currentLocale={locale} setLocale={setLocale} />
        </Box>
      </Box>

      <Box>
        {triggerQuery ? (
          <>
            {activityData ? (
              <DataGrid
                rows={transformActivityData(activityData)}
                columns={columns}
                onRowSelectionModelChange={(newSelection) => {
                  console.log(newSelection);
                  setSelectedIds(newSelection.map((id) => id.toString()) as string[]);
                }}
                slots={{
                  noRowsOverlay: NoRowsOverlay,
                }}
              />
            ) : (
              <AdminPageLoader />
            )}
          </>
        ) : data && data.data ? (
          <DataGrid
            rows={transformData(data)}
            columns={columns}
            onRowSelectionModelChange={(newSelection) => {
              console.log(newSelection);
              setSelectedIds(newSelection.map((id) => id.toString()) as string[]);
            }}
            slots={{
              noRowsOverlay: NoRowsOverlay,
            }}
          />
        ) : (
          <AdminPageLoader />
        )}
      </Box>
    </AdminWrapper>
  );
};

export default DataProtectionUserActivity;
