import { Box, CircularProgress } from '@mui/material';
import Headerdark from 'csam/components/HeaderDark';
import React from 'react';

const PageLoader: React.FC = () => (
  <>
    <Headerdark />
    <Box data-testid="pageLoader" className="pageLoader" sx={{ display: 'flex' }}>
      <CircularProgress sx={{ animationDuration: '550ms' }} />
    </Box>
  </>
);

export default PageLoader;
