import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getCommonColumns } from 'csam/admin/components/CommonColumns';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

const CalendarMonths = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-calendar-months'], (ids) => ({
    url: `${fixed}admin/${locale}/calender_months`,
    method: 'DELETE',
    data: { ids },
  }));
  const queryClient = useQueryClient();
  const handleDelete = (ids?: string[]) => {
    const idsToDelete = ids || selectedIds;
    deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
      onSuccess: () => {
        toast.success(`Record Deleted Successfully`);
        queryClient.invalidateQueries({
          queryKey: ['CalendarMonthPage', 'CalendarMonthData', locale],
        });
      },
    });
  };
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      renderCell: (params) => params.value,
    },
    { field: 'month', headerName: 'Month', width: 100 },
    {
      field: 'year',
      headerName: 'Year',
      width: 100,
      renderCell: (params) => (params.row.year ? params.row.year.year : 'N/A'),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      renderCell: (params) => (Number(params.value) === 1 ? 'Active' : 'Inactive'),
    },
    {
      field: 'title',
      headerName: 'Title of the month',
      width: 300,
      renderCell: (params) => (params.row.title ? params.row.title : 'N/A'),
    },
    ...getCommonColumns({ handleDelete }),
  ];

  interface CalendarMonth {
    id: number;
    month: string;
    title: string;
    created_at: string;
    status: string;
  }

  const relations = ['year'];
  const relationsParam = relations.join(',');

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<CalendarMonth[]>(
    ['CalendarMonthPage', 'CalendarMonthData', locale],
    {
      url: `${fixed}admin/${locale || localeFromStorage}/calender_months?relations=${relationsParam}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  return (
    <AdminWrapper>
      <AdminPageHeader
        title="Calendar Months"
        locale={locale}
        setLocale={setLocale}
        createLink="create"
        enableBulkDelete
      />
      <AdminDataGrid<CalendarMonth>
        data={data}
        columns={columns}
        setSelectedIds={setSelectedIds}
      />
  </AdminWrapper>
  );
};

export default CalendarMonths;
