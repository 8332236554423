import { Box, CircularProgress } from '@mui/material';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import React from 'react';

const AdminPageLoader: React.FC = () => (
  <AdminWrapper>
    <Box sx={{ position: 'relative', height: '100vh', margin: '0 -24px' }}>
      <Box className="adminPageLoader" sx={{ display: 'flex' }}>
        <CircularProgress color="secondary" sx={{ animationDuration: '550ms' }} />
      </Box>
    </Box>
  </AdminWrapper>
);

export default AdminPageLoader;
