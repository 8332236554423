import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getCommonColumns } from 'csam/admin/components/CommonColumns';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

const DataProtectionBanner = () => {
    const { locale, setLocale } = useContext(LocaleContext);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
  
    const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-banner'], (ids) => ({
      url: `${fixed}admin/${locale}/banners`,
      method: 'DELETE',
      data: { ids },
    }));
  
    const queryClient = useQueryClient();
  
    const handleDelete = (ids?: string[]) => {
      const idsToDelete = ids || selectedIds;
      deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
        onSuccess: () => {
          toast.success(`Record Deleted Successfully`);
          queryClient.invalidateQueries({
            queryKey: ['BannerPage', 'BannerPageData', locale],
          });
        },
      });
    };
  
    const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'Id',
        filterable: false,
        width: 40,
        renderCell: (params) => params.value,
      },
  
      {
        field: 'status',
        headerName: 'Status',
        width: 80,
        renderCell: (params) => (Number(params.value) === 1 ? 'Active' : 'Inactive'),
      },
      { field: 'page', headerName: 'Page Name', width: 160 },
      { field: 'heading', headerName: 'Heading' },
      { field: 'description', headerName: 'Description' },
      ...getCommonColumns({ handleDelete }),
    ];
  
    interface BannerData {
        id: number;
        page: string;
        sub_heading: string;
        heading: string;
        description: string;
        created_at: string;
        status: number;
      }
  
    const relations = ['year'];
    const relationsParam = relations.join(',');
  
    const localeFromStorage = localStorage.getItem('locale') || 'en';
    const { isPending, error, data } = useAuthenticatedQuery<BannerData[]>(['BannerPage', 'BannerPageData', locale], {
      url: `${fixed}admin/${locale || localeFromStorage}/banners?relations=${relationsParam}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  
    if (isPending) return <AdminPageLoader />;
    if (error) return <div>Error: {error.message}</div>;
    if (data && !data.success) return <AdminInternalError />;

    

    const filteredData = Array.isArray(data?.data) ? data.data.filter((item) => item.page === 'Data Protection') : [];
    console.log('Filtered data:', filteredData);


  
    return (
      <AdminWrapper>
          <AdminPageHeader
            title="Banners"
            locale={locale}
            setLocale={setLocale}
            createLink="create"
            enableBulkDelete
          />
          <AdminDataGrid<BannerData>
            data={{ data: filteredData }} 
            columns={columns}
            setSelectedIds={setSelectedIds}
          />
      </AdminWrapper>
    );
}

export default DataProtectionBanner