import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed, getImage } from 'csam/utils/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface QuizData {
  year_id: number;
  status: number;
  name: string;
  description: string;
  image: string | File;
  [key: string]: string | number | File;
}

interface TranslationsData {
  name: string;
  description: string;
  image: string | File;
  [key: string]: unknown;
}

interface State {
  data: QuizData;
  translations: {
    es: TranslationsData;
    pt: TranslationsData;
    zh: TranslationsData;
  };
}

const AddQuiz: React.FC = () => {
  const initialState = {
    data: { year_id: 0, name: '', description: '', image: '', status: 0 },
    translations: {
      es: { name: '', description: '', image: '' },
      pt: { name: '', description: '', image: '' },
      zh: { name: '', description: '', image: '' },
    },
  };

  const [quizData, setQuizData] = useState<State>(initialState);
  const [loading, setLoading] = useState(false);
  const imageName = quizData.data.image;

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const { locale } = useContext(LocaleContext);

  const slug = `quiz_contests`;

  const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
    slug,
    initialState,
  );

  useEffect(() => {
    if (!id) {
      setLocale('en');
    }
  }, [id, setLocale]);

  const query = useAuthenticatedQuery(
    ['quiz', id],
    {
      url: `${fixed}admin/en/quiz_contests/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
      setQuizData(query.data.data as State);
    }
  }, [query.data]);

  const isUpdateForm = Boolean(id);

  // const mutation = useAuthenticatedMutation(isUpdateForm ? ['update-quiz', 'PUT'] : ['add-quiz', 'POST'], (data) => ({
  //   url: `${fixed}admin/${locale}/quiz_contests${isUpdateForm ? `/${id}` : ''}`,
  //   method: isUpdateForm ? 'PUT' : 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   data,
  // }));

  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['update-quiz', 'PUT'] : ['add-quiz', 'POST'],

    (data) => {
      const headers = data instanceof FormData ? {} : { 'Content-Type': 'application/json' };

      return {
        url: `${fixed}admin/${locale}/quiz_contests${isUpdateForm ? `/${id}` : ''}`,

        method: isUpdateForm ? 'PUT' : 'POST',

        headers,

        data,
      };
    },
  );

  const {
    data: yearsData,
    error: yearsError,
    isPending: yearIsPending,
  } = useAuthenticatedQuery(['fetch-years', 'GET'], {
    url: `${fixed}admin/en/calender_years`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  interface YearData {
    id: number;
    year_id: string;
    year: string;
  }

  if (yearIsPending) return <AdminPageLoader />;
  if (yearsError) return <div>Error: {yearsError.message}</div>;
  if (yearsData && !yearsData.success) return <AdminInternalError />;

  if (translatePending) return <AdminPageLoader />;
  if (errorTranslate) return <div>Error: {errorTranslate.message}</div>;
  if (translateData && !translateData.success) return <AdminInternalError />;

  const handleChange = (event: { target: { name: string; value: string | number; checked?: boolean } }) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? 1 : 0;
    }

    if (translateData) {
      const translatableFields = translateData.data;

      setQuizData((prevState) => {
        let newData = prevState.data;
        let newTranslations = prevState.translations;

        if (locale !== 'en' && translatableFields.includes(name)) {
          newTranslations = {
            ...prevState.translations,
            [locale as keyof typeof prevState.translations]: {
              ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
              [name]: value,
            },
          };
        } else {
          newData = {
            ...prevState.data,
            [name]: value,
          };
        }

        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const date = new Date().toISOString();
      const tableName = 'quiz_contests';
      let fileName;

      if (locale === 'en') {
        fileName = `${date}_${tableName}_${file.name}`;
      } else {
        fileName = `${locale}_${tableName}_${file.name}`;
      }

      const newFile = new File([file], fileName, { type: file.type });

      setQuizData((prevData) => {
        let newData = { ...prevData.data };
        let newTranslations = { ...prevData.translations };
        if (locale === 'en') {
          newData = {
            ...newData,
            [event.target.name]: newFile,
          };
        } else {
          newTranslations = {
            ...newTranslations,
            [locale]: {
              ...(newTranslations[locale as keyof State['translations']] || {}),
              [event.target.name]: newFile,
            },
          };
        }
        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (value instanceof File) {
        formData.append(fullKey, value);
      } else if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const data = { ...quizData };

    if (data) {
      delete data.translations;
    }

    appendToFormData(formData, data, 'data');

    if (quizData.data.image instanceof File) {
      formData.append('image', quizData.data.image);
    }

    if (quizData?.translations) {
      Object.entries(quizData.translations).forEach(([localeKey, translation]) => {
        appendToFormData(formData, translation, `translations[${localeKey}]`);
      });
    }

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/quiz');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetLocale = (newLocale: string) => {
    if (locale === 'en' && newLocale !== 'en') {
      for (const key in quizData.data) {
        if (
          quizData.data[key] === undefined ||
          quizData.data[key] === '' ||
          (quizData.data[key] === 0 && key !== 'status')
        ) {
          toast.error(`Please fill in the ${key} field first`);
          return;
        }
      }
    }
    setLocale(newLocale);
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit Quiz' : 'Add Quiz'} </Typography>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              fullWidth
              labelId="year-label"
              name="year_id"
              size="small"
              value={quizData.data.year_id === 0 ? '' : quizData.data.year_id.toString()}
              onChange={handleChange}
              disabled={disabledFields.includes('year_id')}
              required={locale === 'en'}
            >
              {(yearsData.data as YearData[]).map((year: YearData) => (
                <MenuItem key={year.id} value={year.id.toString()}>
                  {year.year}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="monthTitle-label">
              Quiz Title <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              placeholder="Add Quiz Title"
              size="small"
              name="name"
              value={
                locale === 'en'
                  ? quizData.data.name
                  : quizData.translations[locale as keyof State['translations']]?.name || ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('name')}
              required={locale === 'en'}
              fullWidth
            />
          </Grid>

          <Grid item sm={12}>
            <Box className="form-group">
              <InputLabel id="monthTitle-label">
                Description <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Description"
                size="small"
                name="description"
                value={
                  locale === 'en'
                    ? quizData.data.description
                    : quizData.translations[locale as keyof State['translations']]?.description || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('description')}
                required={locale === 'en'}
                type="textarea"
                multiline
                minRows={3}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="monthTitle-label">
              Quiz Image <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              type="file"
              name="image"
              onChange={handleImageChange}
              disabled={disabledFields.includes('image')}
              required={locale === 'en' && !imageName}
              fullWidth
            />
            {imageName && (
              <img
                className="formImage"
                src={`${getImage}quiz_contests/${locale === 'en' ? imageName : quizData.translations[locale as keyof typeof quizData.translations]?.image}`}
                alt=""
              />
            )}
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="status-label">Status</InputLabel>
            <Grid container alignItems="center">
              <Typography>Inactive</Typography>
              <Switch
                id="status-label"
                name="status"
                onChange={handleChange}
                disabled={locale !== 'en'}
                checked={Boolean(quizData.data.status)}
              />
              <Typography>Active</Typography>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AdminWrapper>
  );
};
export default AddQuiz;
