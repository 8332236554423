import { Box, Typography } from '@mui/material';
import Videobg from 'csam/assets/video/bg_video.mp4';
import Headerdark from 'csam/components/HeaderDark';
import errorImg from 'csam/images/error.png';
import React from 'react';

const InternalServerError: React.FC = () => (
  <>
    <Headerdark />
    <Box className="errorWrapper">
      <video autoPlay loop muted className="errorBgVideo">
        <source src={Videobg} type="video/mp4" />
      </video>
      <Box className="errorContent">
        <img className="errorIcon" src={errorImg} alt="" />
        <Typography variant="h1">Internal Server Error</Typography>
        <Typography variant="body1">
          We apologize for the inconvenience. Our team is working to fix the issue.
        </Typography>
      </Box>
    </Box>
  </>
);

export default InternalServerError;
