import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed, getImage } from 'csam/utils/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddCalendarMonth = () => {
  interface MonthData {
    year_id: number;
    status: number;
    title: string;
    image: string | File;
    month: string;
    [key: string]: string | number | File;
  }

  interface TranslationData {
    title: string;
    month: string;
    image: string | File;
    [key: string]: unknown;
  }

  interface State {
    data: MonthData;
    translations: {
      es: TranslationData;
      pt: TranslationData;
      zh: TranslationData;
    };
  }

  const initialState = {
    data: { year_id: 0, status: 0, title: '', month: '', image: '' },
    translations: {
      es: { title: '', month: '', image: '' },
      pt: { title: '', month: '', image: '' },
      zh: { title: '', month: '', image: '' },
    },
  };

  const { locale } = useContext(LocaleContext);
  const [loading, setLoading] = useState(false);
  const [monthData, setMonthData] = useState<State>(initialState);
  const imageName = monthData.data.image;

  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const slug = `calender_months`;

  const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
    slug,
    initialState,
  );

  useEffect(() => {
    if (!id) {
      setLocale('en');
    }
  }, [id, setLocale]);

  const query = useAuthenticatedQuery(
    ['calendar-month', id],
    {
      url: `${fixed}admin/en/calender_months/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
      setMonthData(query.data.data as State);
    }
  }, [query.data]);

  const isUpdateForm = Boolean(id);
  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['update-calendar-month', 'PUT'] : ['add-calendar-month', 'POST'],

    (data) => {
      const headers = data instanceof FormData ? {} : { 'Content-Type': 'application/json' };

      return {
        url: `${fixed}admin/${locale}/calender_months${isUpdateForm ? `/${id}` : ''}`,

        method: isUpdateForm ? 'PUT' : 'POST',

        headers,

        data,
      };
    },
  );

  const {
    data: yearsData,
    error: yearsError,
    isPending: yearIsPending,
  } = useAuthenticatedQuery(['fetch-years', 'GET'], {
    url: `${fixed}admin/en/calender_years`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  interface YearData {
    id: number;
    year_id: string;
    year: string;
  }

  if (yearIsPending) return <AdminPageLoader />;
  if (yearsError) return <div>Error: {yearsError.message}</div>;
  if (yearsData && !yearsData.success) return <AdminInternalError />;

  if (translatePending) return <AdminPageLoader />;
  if (errorTranslate) return <div>Error: {errorTranslate.message}</div>;
  if (translateData && !translateData.success) return <AdminInternalError />;

  const handleChange = (event: { target: { name: string; value: string | number; checked?: boolean } }) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? 1 : 0;
    }

    if (translateData) {
      const translatableFields = translateData.data;

      setMonthData((prevState) => {
        let newData = prevState.data;
        let newTranslations = prevState.translations;

        if (locale !== 'en' && translatableFields.includes(name)) {
          newTranslations = {
            ...prevState.translations,
            [locale as keyof typeof prevState.translations]: {
              ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
              [name]: value,
            },
          };
        } else {
          newData = {
            ...prevState.data,
            [name]: value,
          };
        }

        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const date = new Date().toISOString();
      const tableName = 'calender_months';
      let fileName;

      if (locale === 'en') {
        fileName = `${date}_${tableName}_${file.name}`;
      } else {
        fileName = `${locale}_${tableName}_${file.name}`;
      }

      const newFile = new File([file], fileName, { type: file.type });

      setMonthData((prevData) => {
        let newData = { ...prevData.data };
        let newTranslations = { ...prevData.translations };
        if (locale === 'en') {
          newData = {
            ...newData,
            [event.target.name]: newFile,
          };
        } else {
          newTranslations = {
            ...newTranslations,
            [locale]: {
              ...(newTranslations[locale as keyof State['translations']] || {}),
              [event.target.name]: newFile,
            },
          };
        }
        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (value instanceof File) {
        formData.append(fullKey, value);
      } else if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const data = { ...monthData };

    if (data) {
      delete data.translations;
    }

    appendToFormData(formData, data, 'data');

    if (monthData.data.image instanceof File) {
      formData.append('image', monthData.data.image);
    }

    if (monthData?.translations) {
      Object.entries(monthData.translations).forEach(([localeKey, translation]) => {
        appendToFormData(formData, translation, `translations[${localeKey}]`);
      });
    }

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/calendar/months');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetLocale = (newLocale: string) => {
    const isNewOption = Object.keys(monthData.data).length === 0;

    if (isNewOption) {
      setLocale('en');
    } else {
      if (locale === 'en' && newLocale !== 'en') {
        for (const key in monthData.data) {
          if (
            monthData.data[key] === undefined ||
            monthData.data[key] === '' ||
            (monthData.data[key] === 0 && key !== 'status')
          ) {
            toast.error(`Please fill in the ${key} field first`);
            return;
          }
        }
      }

      setLocale(newLocale);
    }
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit Calendar Month' : 'Add Calendar Month'} </Typography>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
      </Box>
      <Card>
        <CardContent>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <InputLabel id="year-label">Year </InputLabel>
                <Select
                  fullWidth
                  labelId="year-label"
                  name="year_id"
                  size="small"
                  value={monthData.data.year_id === 0 ? '' : monthData.data.year_id.toString()}
                  onChange={handleChange}
                  disabled={disabledFields.includes('year_id')}
                  required={locale === 'en'}
                >
                  {(yearsData.data as YearData[]).map((year: YearData) => (
                    <MenuItem key={year.id} value={year.id.toString()}>
                      {year.year}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item sm={6}>
                <InputLabel id="monthName-label">
                  Month Name <Typography component="sup">{locale.toUpperCase()}</Typography>
                </InputLabel>
                <TextField
                  id="monthName-label"
                  name="month"
                  onChange={handleChange}
                  size="small"
                  value={
                    locale === 'en'
                      ? monthData.data.month
                      : monthData.translations[locale as keyof State['translations']]?.month || ''
                  }
                  disabled={disabledFields.includes('month')}
                  fullWidth
                />
              </Grid>

              <Grid item sm={12}>
                <InputLabel id="monthTitle-label">
                  Month Title <Typography component="sup">{locale.toUpperCase()}</Typography>
                </InputLabel>
                <TextField
                  id="monthTitle-label"
                  name="title"
                  size="small"
                  onChange={handleChange}
                  value={
                    locale === 'en'
                      ? monthData.data.title
                      : monthData.translations[locale as keyof State['translations']]?.title || ''
                  }
                  disabled={disabledFields.includes('title')}
                  fullWidth
                />
              </Grid>

              <Grid item sm={12}>
                <Box className="form-group">
                  <InputLabel>
                    Theme of the Month Banner <Typography component="sup">{locale.toUpperCase()}</Typography>
                  </InputLabel>
                  <TextField
                    type="file"
                    name="image"
                    onChange={handleImageChange}
                    disabled={disabledFields.includes('image')}
                    required={locale === 'en' && !imageName}
                    fullWidth
                  />
                  {imageName && (
                    <img
                      className="formImage"
                      src={`${getImage}calender_months/${locale === 'en' ? imageName : monthData.translations[locale as keyof typeof monthData.translations]?.image}`}
                      alt=""
                    />
                  )}
                </Box>
              </Grid>

              <Grid item sm={6}>
                <InputLabel id="status-label">
                  Status{' '}
                  <Typography sx={{ display: 'inline-block', fontSize: '18px' }}>{locale.toUpperCase()}</Typography>
                </InputLabel>
                <Grid container alignItems="center">
                  <Typography>Inactive</Typography>
                  <Switch
                    id="status-label"
                    name="status"
                    onChange={handleChange}
                    checked={Boolean(monthData.data.status)}
                    disabled={locale !== 'en'}
                  />
                  <Typography>Active</Typography>
                </Grid>
              </Grid>

              <Grid item sm={12}>
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                  {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </AdminWrapper>
  );
};

export default AddCalendarMonth;
