import React, { useEffect, useState } from 'react';
import './TrainingPlatform.css';
import Logo from 'csam/images/logo_img.png';
import { DataProtectionURL, fixed } from 'csam/utils/Constants';
import { useMsal } from '@azure/msal-react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Box, Typography, Button, colors } from '@mui/material';

const SESSION_TOKEN_KEY = 'secondSessionToken';
const PROD_PATH = 'public/3rdParty/CyberSecureCity';
const LocalURL = '/ApplicationSecurity';
// const PROD_PATH = 'public/3rdParty/CyberSecurityStage';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  color: 'text.primary',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};
const TrainingPlatform: React.FC = () => {
  const { accounts } = useMsal();
  const user = accounts[0];
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // loginUser();
    
    document.body.classList.add('hideFeedback');
    if (location.pathname === '/en/training-thanks') {
      updateUser();
      setShowModal(true); 
    } else {
      loginUser();
    }
    return () => {
      document.body.classList.remove('hideFeedback');
    };
  }, []);

  const loginUser = async (): Promise<string | null> => {
    if (!user) {
      console.error('No user found in MSAL accounts');
      return null;
    }
  
    const loginPayload = {
      username: user.username,
      email: user.username, 
    };
  
    try {
      const response = await fetch(`${fixed}proxy/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginPayload)
      });
  
      if (!response.ok) {
        throw new Error('Failed to login');
      }
  
      const data = await response.json();
      const token = data.data.access_token;
      console.log('Tokenasas:', token);
      sessionStorage.setItem(SESSION_TOKEN_KEY, token);
      console.log('Login successful, token stored in session storage');
      return token;
    } catch (error) {
      console.error('Error logging in:', error);
      return null;
    }
  };
  const ensureToken = async () => {
    let token = sessionStorage.getItem(SESSION_TOKEN_KEY);
    console.log('Tokens:', token);
    if (!token) {
      console.log('No session token found, logging in...');
      token = await loginUser();
    }
    return token;
  };
  const getUser = async (email: string) => {
    const token = await ensureToken();
    if (!token) {
      console.error('Failed to obtain session token');
      return null;
    }
    console.log('Token:', token);
    try {
      const response = await fetch(`${fixed}proxy/getUser`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, token })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Failed to fetch user:', errorText);
        throw new Error(`Failed to fetch user: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      if (data.userlist && data.userlist.length > 0) {
        return data.userlist[0].username;
      } else {
        console.error('No user found');
        return null;
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      return null;
    }
  };

  const updateUser = async () => {
    const email = user?.username;
    if (!email) {
      console.error('No email found');
      return;
    }

    const username = await getUser(email);
    if (!username) {
      console.error('No username found');
      return;
    }
    console.log('Username:', username);
    const token = await ensureToken();
    if (!token) {
      console.error('Failed to obtain session token');
      return null;
    }

    try {
      const response = await fetch(`${fixed}proxy/updateUser`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, email, token })
      });

      const result = await response.text();
      if (response.ok) {
        navigate('/en/training-platform');
      } else {
        console.error('Failed to update activity');
      }
      console.log(result);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleClick = async (language: string) => {
    if (!user) {
      console.error('No user found in MSAL accounts');
      return;
    }

    const activity = { action: `user clicked on ${language} training` };

    try {
      await fetch(`${fixed}proxy/updateActivity`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: user.username,
          username: user.username,
          activity
        })
      });
    } catch (error) {
      console.error('Error updating activity:', error);
    }
  };

  return (
    <div className="pb-5 trainingsContent">
      <header className="trainingsHeader">
        <div className="brand-logo p-5">
        <Link to="/en">
          <img src={Logo} alt="CSAM" />
        </Link>
        </div>
        <Typography className="common_title mb-4" variant="commonTitle" component="h4"> WELCOME TO ABI 3rd PARTY LEARNING PLATFORM </Typography>
      </header>
      <div id="block" className='text-center'>
        <fieldset id="one"  className='text-center'>
          <legend>
            General Cybersecurity Awareness Training
            <br />
          </legend>
          <small className="d-block text-end">Choose a language</small>
          <a
            target="_blank"
            href={`${LocalURL}/2023_English/story.html`}
            onClick={() => handleClick('')}
          >
            <div id="language">English Module</div>
          </a>
          <a
            target="_blank"
            href={`${LocalURL}/2023_Chinese/story.html`}
            onClick={() => handleClick('')}
          >
            <div id="language">Chinese Module</div>
          </a>
          <a
            target="_blank"
            href={`${LocalURL}/2023_Spanish/story.html`}
            onClick={() => handleClick('')}
          >
            <div id="language">Spanish Module</div>
          </a>
          <a
            target="_blank"
            href={`${LocalURL}/2023_Portuguese/story.html`}
            onClick={() => handleClick('')}
          >
            <div id="language">Portuguese Module</div>
          </a>
        </fieldset>
      </div>
      <Modal
        open={showModal}
        onClose={() => {}}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h1" component="h1">
            Thank You!
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            You have completed your training.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/en')}
            sx={{ mt: 2 }}
          >
            Click here to explore Cyber Security Awareness portal
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default TrainingPlatform;