import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';

interface CommonColumnsProps {
  handleDelete: (ids: string[]) => void;
}

export function getCommonColumns({ handleDelete }: CommonColumnsProps): GridColDef[] {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
        field: 'year',
        headerName: 'Year',
        width: 100,
        renderCell: (params) => (params.row.year ? params.row.year.year : 'N/A'),
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        width: 120,
        renderCell: (params) => {
          const date = new Date(params.value);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 160,
        renderCell: (params) => (
          <>
            <Button
              variant="contained"
              sx={{ py: 1, px: 2, mr: 1, minWidth: 'fit-content' }}
              color="secondary"
              onClick={() => {
                navigate(`edit/${params.row.id}`);
              }}
            >
              <EditIcon sx={{ fontSize: '18px' }} />
            </Button>
            <Button
              variant="contained"
              sx={{ py: 1, px: 2, mr: 1, minWidth: 'fit-content' }}
              onClick={() => handleDelete([params.row.id])}
              color="error"
            >
              <DeleteOutlineIcon sx={{ fontSize: '18px' }} />
            </Button>
          </>
        ),
      },
  ];

  return columns;
}