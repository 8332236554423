import { Box, Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import Footer from 'csam/admin/components/Footer';
import Header from 'csam/admin/components/Header';
import Sidebar from 'csam/admin/components/Sidebar';
import React, { useEffect, useState } from 'react';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#008eff',
    },
    secondary: {
      main: '#edc200',
    },
    success: {
      main: '#009206',
    },
    background: {
      default: '#121212',
      paper: '#121212',
    },
    text: {
      primary: '#fff',
      secondary: '#ffffffb3',
    },
    action: {
      active: '#fff',
      hover: '#121212',
      selected: '#121212',
    },
    divider: '#fdffff1f',
  },
  typography: {
    fontFamily: 'Avantt, sans-serif',
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& $notchedOutline': {
            borderColor: '#ffffff80',
          },
          '&:hover $notchedOutline': {
            borderColor: '#fff',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: '#90caf9',
          },
          marginBottom: '5px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          disableScrollLock: true,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#000',
          '&:hover': {
            backgroundColor: '#191919',
          },
          '&.Mui-selected': {
            backgroundColor: '#000',
          },
        },
      },
    },
  },
});

const AdminWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    document.body.classList.add('admin');
    return () => {
      document.body.classList.remove('admin');
    };
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header open={open} toggleDrawer={toggleDrawer} />
        <Sidebar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            mt: 6,
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 5, mb: 4 }}>
            {children}
          </Container>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminWrapper;
