import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useState } from 'react';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';

const Survay: React.FC = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-survey-item'], (ids) => ({
    url: `${fixed}admin/${locale}/opps_survey`,
    method: 'DELETE',
    data: { ids },
  }));

  const queryClient = useQueryClient();

  const handleDelete = (ids?: string[]) => {
    const idsToDelete = ids || selectedIds;
    deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
      onSuccess: () => {
        toast.success(`Record Deleted Successfully`);
        queryClient.invalidateQueries({
          queryKey: ['SurveyPage', 'SurveyPageData', locale],
        });
      },
    });
  };

  const headers = [
    { label: 'Id', key: 'id' },
    { label: 'Status', key: 'status' },
    { label: 'User ID', key: 'user_id' },
    { label: 'Email', key: 'user.email' },
    { label: 'Selected Option', key: 'selected_option' },
    { label: 'Other Option', key: 'other_option' },
    { label: 'Created At', key: 'created_at' },
  ];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 40,
      renderCell: (params) => params.value,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      renderCell: (params) => (Number(params.value) === 1 ? 'Active' : 'Inactive'),
    },
    {
      field: 'user_id',
      headerName: 'User ID',
      width: 100,
      renderCell: (params) => (params.row.user_id ? params.row.user_id.user_id : 'N/A'),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      renderCell: (params) => (params.row.user ? params.row.user.email : 'N/A'),
    },
    { field: 'selected_option', headerName: 'Options Selected', width: 300 },
    { field: 'other_option', headerName: 'Other Option', width: 300 },

    {
      field: 'created_at',
      headerName: 'Created At',
      width: 120,
      renderCell: (params) => {
        const date = new Date(params.value);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 160,
      renderCell: (params) => (
        <Button
          variant="contained"
          sx={{ py: 1, px: 2, mr: 1, minWidth: 'fit-content' }}
          onClick={() => handleDelete([params.row.id])}
          color="error"
        >
          <DeleteOutlineIcon sx={{ fontSize: '18px' }} />
        </Button>
      ),
    },
  ];

  interface GamesData {
    id: number;
    game_title: string;
    link: string;
    created_at: string;
    status: number;
  }

  interface GamesResponse {
    success: boolean;
    data: GamesData;
    translatableFields: string[];
  }

  const relations = ['user'];
  const relationsParam = relations.join(',');

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<GamesResponse[]>(['SurveyPage', 'SurveyPageData', locale], {
    url: `${fixed}admin/${locale || localeFromStorage}/opps_survey?relations=${relationsParam}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;
  console.log(data);

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6">Oops Survey</Typography>
          <Button
            type="button"
            sx={{ color: '#fff', lineHeight: '26px' }}
            variant="contained"
            size="small"
            color="success"
            className="download-csv-button"
          >
            <CSVLink data={data.data} headers={headers}>
              <CloudDownloadIcon sx={{ fontSize: '18px' }} /> CSV
            </CSVLink>
          </Button>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={setLocale} />
      </Box>

      <Box>
        <DataGrid
          rows={data?.data as unknown as readonly GamesData[]}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) => {
            console.log(newSelection);
            setSelectedIds(newSelection.map((id) => id.toString()) as string[]);
          }}
        />
      </Box>
    </AdminWrapper>
  );
};

export default Survay;
