import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getCommonColumns } from 'csam/admin/components/CommonColumns';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

const CalendarWeeks: React.FC = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-week'], (ids) => ({
    url: `${fixed}admin/${locale}/calender_weeks`,
    method: 'DELETE',
    data: { ids },
  }));

  const queryClient = useQueryClient();
  const handleDelete = (ids?: string[]) => {
    const idsToDelete = ids || selectedIds;
    deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
      onSuccess: () => {
        toast.success(`Record Deleted Successfully`);
        queryClient.invalidateQueries({
          queryKey: ['WeeksPage', 'WeeksData', locale],
        });
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 40,
      renderCell: (params) => params.value,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      renderCell: (params) => (Number(params.value) === 1 ? 'Active' : 'Inactive'),
    },
    {
      field: 'month',
      headerName: 'Month',
      width: 100,
      renderCell: (params) => (params.row.month ? params.row.month.month : 'N/A'),
    },
    { field: 'week', headerName: 'Week', width: 200 },
    {
      field: 'tip_title',
      headerName: 'Tip Title',
      width: 200,
      renderCell: (params) => (params.row.tip_title ? params.row.tip_title : 'N/A'),
    },
    {
      field: 'video_title',
      headerName: 'Video Title',
      width: 200,
      renderCell: (params) => (params.row.video_title ? params.row.video_title : 'N/A'),
    },

    ...getCommonColumns({ handleDelete }),
  ];

  interface WeeksData {
    id: number;
    month_id: number;
    week: string;
    tip_title: string;
    video_title: string;
    created_at: string;
    status: number;
  }

  const relations = ['month'];
  const relationsParam = relations.join(',');

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<WeeksData[]>(['WeeksPage', 'WeeksData', locale], {
    url: `${fixed}admin/${locale || localeFromStorage}/calender_weeks?relations=${relationsParam}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  return (
    <AdminWrapper>
        <AdminPageHeader
          title="Calendar Weeks"
          locale={locale}
          setLocale={setLocale}
          createLink="create"
          enableBulkDelete
        />
        <AdminDataGrid<WeeksData>
          data={data}
          columns={columns}
          setSelectedIds={setSelectedIds}
        />
    </AdminWrapper>
  );
};

export default CalendarWeeks;
