import { IMsalContext, useMsal } from '@azure/msal-react';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { type AxiosRequestConfig, AxiosResponse } from 'axios';
import { fixed } from 'csam/utils/Constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const AUTH_TOKEN_KEY = 'Auth_token';
export type Response<T> = { success: true; data: T } | { success: false; err?: AxiosResponse | undefined };

type AuthResponse = { token_type: string; access_token: string };
type NavigateFunction = ReturnType<typeof useNavigate>;

const getAuthToken = async (msalDetails: IMsalContext, navigate: NavigateFunction) => {
  // console.log('getAuthToken',sessionStorage.getItem(AUTH_TOKEN_KEY));
  if (!sessionStorage.getItem(AUTH_TOKEN_KEY)) {
    const account = msalDetails.accounts[0];
    let authResponse;
    try {
      authResponse = await axios<AuthResponse>({
        url: `${fixed}users/sso-login`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: account,
      });
    } catch (err) {
      console.debug(err);
      return null;
    }
    if (authResponse.status === 200) {
      sessionStorage.setItem(AUTH_TOKEN_KEY, authResponse.data.access_token);
      return `Bearer ${authResponse.data.access_token}`;
    } else if (authResponse.status === 404) {
      navigate('/Gamecontests');
      return null;
    } else {
      console.error(authResponse);
      return null;
    }
  }
  const token = sessionStorage.getItem(AUTH_TOKEN_KEY);
  if (token) {
    return `Bearer ${token}`;
  } else {
    return null;
  }
};

export const useIsAuthorized = () => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        await getAuthToken(msalDetails, navigate);
      } catch (err) {
        console.debug(err);
      }
    })();
  }, [msalDetails, navigate]);
};

const httpCall = async <T>(
  config: AxiosRequestConfig,
  msalDetails: IMsalContext,
  navigate: NavigateFunction,
): Promise<Response<T>> => {
  const token = await getAuthToken(msalDetails, navigate);
  if (!token) return { success: false };

  const headerConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: token,
      'Cache-Control': 'no-store,no-cache,',
      Pragma: 'no-cache',
    },
  };
  try {
    const response = await axios<T>(headerConfig);
    return {
      success: true,
      data: response.data,
    };
  } catch (err) {
    // console.error(err);
    if (axios.isAxiosError(err)) {
      if(err.response?.status === 401) {
        console.log('Token expired 401');
        sessionStorage.removeItem(AUTH_TOKEN_KEY);
        // return { success: false, err: err.response };
        const newToken = await getAuthToken(msalDetails, navigate);
        if (!newToken) return { success: false };
        console.log('newToken', newToken);
        if (newToken) {
          // Retry the original request with the new token
          const retryConfig: AxiosRequestConfig = {
            ...config,
            headers: {
              ...(config.headers ?? {}),
              Authorization: newToken,
              'Cache-Control': 'no-store,no-cache,',
              Pragma: 'no-cache',
            },
          };
          try {
            const retryResponse = await axios<T>(retryConfig);
            console.log('retryResponse', retryResponse);
            return {
              success: true,
              data: retryResponse.data,
            };
          } catch (retryErr) {
            console.error('retryErr', retryErr);
            return { success: false, err: retryErr.response };
          }
        } else {
          return { success: false, err: err.response };
        }
      } else {
        return { success: false, err: err.response };
      }
    }
    return { success: false };
  }
};

export const useAuthenticatedQuery = <T>(
  queryKey: unknown[],
  queryConfig: AxiosRequestConfig,
  options?: {
    refetchOnWindowFocus?: boolean;
    enabled?: boolean;
  },
): UseQueryResult<Response<T>> => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  const result = useQuery({
    queryKey,
    queryFn: () => httpCall<T>(queryConfig, msalDetails, navigate),
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    retry: false,
    enabled: options?.enabled ?? true,
  });
  const { isPending, error, data } = result;
  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (import.meta.env.DEV) {
      if (isPending) {
        console.debug(queryKey, 'Pending');
      } else {
        console.debug(queryKey, data);
      }
    }
  }, [error, isPending, data, queryKey]);
  return result;
};

export const useAuthenticatedMutation = <T, MutationData>(
  mutationKey: unknown[],
  getMutationConfig: (data: MutationData) => AxiosRequestConfig,
): UseMutationResult<Response<T>, unknown, MutationData, unknown> => {
  const msalDetails = useMsal();
  const navigate = useNavigate();
  const result = useMutation<Response<T>, unknown, MutationData, unknown>({
    mutationKey,
    mutationFn: (data: MutationData) => httpCall<T>(getMutationConfig(data), msalDetails, navigate),
    retry: false,
  });
  const { isIdle, error, data } = result;
  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (import.meta.env.DEV) {
      if (isIdle) {
        console.debug(mutationKey, 'Idle');
      } else {
        console.debug(mutationKey, data);
      }
    }
  }, [error, isIdle, data, mutationKey]);
  return result;
};

type ResponseData = {
  isAdmin: boolean;
  success: boolean;
  data?: {
    isAdmin?: boolean;
  };
};

export const useAdminAuthorized = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useAuthenticatedQuery<ResponseData>(['getUser'], {
    url: `${fixed}users/get-is-admin`,
    method: 'GET',
  });

  useEffect(() => {
    if (isError) {
      navigate('/404');
    }
  }, [isError, navigate]);

  const isAdmin = data?.success ? data.data?.isAdmin : undefined;
  return { isAdmin, isLoading };
};

export const useFeedbackMutation = <T, MutationData>(
  mutationKey: unknown[],
  getMutationConfig: (data: MutationData) => AxiosRequestConfig,
): UseMutationResult<Response<T>, unknown, MutationData, unknown> => {
  const msalDetails = useMsal();
  const result = useMutation<Response<T>, unknown, MutationData, unknown>({
    mutationKey,
    mutationFn: (data: MutationData) =>
      httpCall<T>(getMutationConfig(data), msalDetails, 'null' as unknown as NavigateFunction),
    retry: false,
  });
  const { isIdle, error, data } = result;
  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (import.meta.env.DEV) {
      if (isIdle) {
        console.debug(mutationKey, 'Idle');
      } else {
        console.debug(mutationKey, data);
      }
    }
  }, [error, isIdle, data, mutationKey]);
  return result;
};

export const logout = (msalDetails: IMsalContext) => {
  sessionStorage.removeItem(AUTH_TOKEN_KEY);
  msalDetails.instance.logout();
};

export const getUserImage = async (msalDetails: IMsalContext): Promise<string | null> => {
  const account = msalDetails.accounts[0];
  if (!account) {
    console.error('No account found');
    return null;
  }

  const loginRequest = {
    scopes: ['User.Read'],
    account,
  };

  try {
    const response = await msalDetails.instance.acquireTokenSilent(loginRequest);
    const imageUrl = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
      headers: {
        Authorization: `Bearer ${response.accessToken}`,
      },
    })
      .then((fetchResponse) => fetchResponse.blob())
      .then((imageBlob) => URL.createObjectURL(imageBlob));

    return imageUrl;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchQuery = async (endpoint: string, data?: unknown, method: string = 'POST') => {
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (method !== 'GET' && method !== 'HEAD') {
    options.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(`${import.meta.env.VITE_API_URL}${endpoint}`, options);
    console.log('response', response);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    toast.success('Request successful');
    return responseData;
  } catch (error) {
    const errorMessage = (error as Error).message;
    toast.error(`Request failed: ${errorMessage}`);
    throw error;
  }
};

export default fetchQuery;
