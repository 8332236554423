import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const PhishingGuideline: React.FC = () => {
  return (
    <Box className="guidelines mb-5">
      <Container>
        <Box className="guidelines-main">
          <Box className="number-wrapper-1">
            <Typography component="span" className="number-wrapper">
              <Typography component="span" className="white">
                Guidelines
              </Typography>
            </Typography>
          </Box>
          <ul>
            <li>Only ABI colleagues can participate in this contest</li>
            <li>Only 1 entry per colleague</li>
            <li>
              ABI Security awareness team reserves the right to use the creative content for internal communications or
              awareness purposes, with due credit being assigned to the author
            </li>
            <li>Submit your phishing email on the site</li>
            <li>
              Make sure to submit your entries before <span className="text-abi-yellow">30 October, 2024</span>
            </li>
          </ul>
        </Box>
      </Container>
    </Box>
  );
};

export default PhishingGuideline;
