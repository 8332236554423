import React, { useState } from 'react';

const LocaleContext = React.createContext({
  locale: 'en',
  setLocale: (_en: string) => {},
});

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [locale, setLocale] = useState('en');

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const LocaleConsumer = LocaleContext.Consumer;

export default LocaleContext;