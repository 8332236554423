import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const NumbersBackground: React.FC = () => {
  return (
    <Container className="numbers-bg" sx={{ pb: 5, pt: 5 }}>
      <Box className="winners">
        <Box className="winners-main winners-phising">
          <Typography variant="h4">Winners will be chosen, based on the following parameters:</Typography>
          <ul>
            <li data-aos="fade-up" data-aos-duration="1500" className="aos-init aos-animate">
              <Box className="numbers">1</Box>
              <span>
                Uniqueness and <br />
                Creativity of Idea
              </span>
            </li>
            <li data-aos="fade-up" data-aos-duration="2000" className="aos-init aos-animate">
              <Box className="numbers">2</Box>
              <span>
                Number of phishing <br />
                indicators used in the email
              </span>
            </li>
          </ul>
        </Box>
      </Box>
    </Container>
  );
};

export default NumbersBackground;
