import { useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useEffect, useState } from 'react';

type State = {
  data: {
    [key: string]: unknown;
  };
  translations: {
    [key: string]: {
      [key: string]: unknown;
    };
  };
};

const useTranslatableFields = <T>(slug: string, initialState: State) => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [fromData] = useState<State>(initialState);

  const {
    isPending: translatePending,
    error: errorTranslate,
    data: translateData,
  } = useAuthenticatedQuery<T[]>(['TranslationsData', 'TranslationsArray', locale], {
    url: `${fixed}admin/${locale}/${slug}/translatableFields`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  useEffect(() => {
    if (translateData && translateData.success && locale !== 'en') {
      const allFields = Object.keys(fromData.data);
      const translatableFields = translateData.data;
      const newDisabledFields = allFields.filter((field) => locale !== 'en' && !translatableFields.includes(field));
      setDisabledFields(newDisabledFields);
    }
  }, [translateData, locale, fromData.data]);

  return { translatePending, errorTranslate, translateData, disabledFields, fromData, setLocale };
};

export default useTranslatableFields;
