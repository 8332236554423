import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getCommonColumns } from 'csam/admin/components/CommonColumns';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

const OctPledgeVideo: React.FC = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-octPledge-video'], (ids) => ({
    url: `${fixed}admin/${locale}/oct_pledge_admin`,
    method: 'DELETE',
    data: { ids },
  }));

  const queryClient = useQueryClient();

  const handleDelete = (ids?: string[]) => {
    const idsToDelete = ids || selectedIds;
    deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
      onSuccess: () => {
        toast.success(`Record Deleted Successfully`);
        queryClient.invalidateQueries({
          queryKey: ['OctPledgeVideo', 'OctPledgeVideoData', locale],
        });
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 40,
      renderCell: (params) => params.value,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (Number(params.value) === 1 ? 'Active' : 'Inactive'),
    },
    { field: 'video', headerName: 'Video', width: 400 },
    ...getCommonColumns({ handleDelete }),
  ];

  interface OctVideoData {
    id: number;
    video: string;
    created_at: string;
    status: number;
  }

  interface OctVideoDataResponse {
    success: boolean;
    data: OctVideoData;
    translatableFields: string[];
  }

  const relations = ['year'];
  const relationsParam = relations.join(',');

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<OctVideoDataResponse[]>(
    ['OctPledgeVideo', 'OctPledgeVideoData', locale],
    {
      url: `${fixed}admin/${locale || localeFromStorage}/oct_pledge_admin?relations=${relationsParam}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  return (
    <AdminWrapper>
      <AdminPageHeader
        title="October Pledge Video"
        locale={locale}
        setLocale={setLocale}
        createLink="create"
        enableBulkDelete
      />
      <AdminDataGrid<OctVideoDataResponse> data={data} columns={columns} setSelectedIds={setSelectedIds} />
    </AdminWrapper>
  );
};

export default OctPledgeVideo;
